import { API_URL } from "../..";
import { RequestService } from "../../store/models/request/request.service";
import { ISliderSlide } from "../../store/models/slider/typing/slide.interface";
import { ISliderService } from "./typing/slider.interface";

export class SliderService implements ISliderService {
  public getSlides = async () => {
    const request = new RequestService();
    return await request.run<{ slides: ISliderSlide[] }>(
      `${API_URL}/api/slide/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
}

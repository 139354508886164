import React, { useState } from "react";
import { MainSlider } from "../../components/MainSlider/mainSlider";
import { Slots } from "../../components/Slots/Slots";
import { useSearchParams } from "react-router-dom";
import { Activation } from "../../components/activation/activation";
import { Slider } from "../../components/slider/slider";
import analytics from "../../components/customer.io/analytics";

const Main = () => {
  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setParams((prev) => {
      prev.delete("activate");
      return prev;
    });
  };
  analytics.page();
  return (
    <React.Fragment>
      <Slider />
      {/* <MainSlider /> */}
      <Slots />
      <Activation
        param={params.get("activate")}
        handleClose={handleClose}
        loading={loading}
        setLoading={setLoading}
      />
    </React.Fragment>
  );
};

export default Main;

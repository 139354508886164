import { Box, Link } from "@mui/material";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./slider.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import { getSlides } from "../../store/models/slider/slider.thunk";
import { SliderSelector } from "./slide.selector";

export const Slider = () => {
  const dispatch = useAppDispatch();
  const { slider } = useAppSelector(SliderSelector);
  useEffect(() => {
    dispatch(getSlides());
  }, []);
  return (
    <>
      <Box
        sx={{
          marginTop: { sm: "84px", xs: "112px" },
        }}
      >
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={"auto"}
          rewind={true}
          navigation={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            stopOnLastSlide: false,
            waitForTransition: true,
          }}
        >
          {slider.map((slide, index) => {
            return (
              <SwiperSlide
                key={`slide_${slide.id}`}
                style={{
                  maxWidth: "700px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: { sm: "350px", xs: "250px" },
                    backgroundColor: "var(--secondary-background-color)",
                    borderRadius: "var(--primary-border-radius)",
                    boxShadow: "var(--primary-shadow)",
                    overflow: "hidden",
                    position: "relative",
                    padding: { sm: "0 36px", xs: "0 8px" },
                    color: "var(--primary-text-color)",
                  }}
                >
                  {slide.mobile_bg_image && (
                    <img
                      className="slider-background-image"
                      src={slide.mobile_bg_image}
                      alt={slide.mobile_bg_image}
                    />
                  )}
                  {slide.mobile_sub_image && (
                    <img
                      className="slider-sub-image"
                      src={slide.mobile_sub_image}
                      alt={slide.mobile_sub_image}
                    />
                  )}
                  {slide.title && (
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 1,
                        fontSize: { sm: "24px", xs: "18px" },
                        maxWidth: { sm: "50%", xs: "60%" },
                        fontWeight: 800,
                        margin: { sm: "110px 0 16px 0", xs: "75px 0 10px 0" },
                      }}
                    >
                      {slide.title}
                    </Box>
                  )}
                  {slide.sub_title && (
                    <Box
                      sx={{
                        flex: "1 1 auto",
                        position: "relative",
                        zIndex: 1,
                        maxWidth: { sm: "60%", xs: "70%" },
                        fontSize: { sm: "20px", xs: "14px" },
                        fontWeight: 600,
                      }}
                    >
                      {slide.sub_title}
                    </Box>
                  )}
                  {slide.url && (
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Link
                        href={slide.url}
                        className="Button Button-secondary"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textDecoration: "none",
                          borderRadius: "var(--primary-border-radius)",
                          minWidth: "150px",
                          padding: "6px 12px",
                          marginBottom: { sm: "36px", xs: "24px" },
                        }}
                      >
                        {slide.button
                          ? slide.button
                          : slide.bonus
                          ? "Детальнее"
                          : "Играть"}
                      </Link>
                    </Box>
                  )}
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </>
  );
};

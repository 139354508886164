import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import "./RequestPassword.css";
import TextField from "@mui/material/TextField";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Form, Formik } from "formik";
import {
  RequestPasswordAuthSchema,
  RequestPasswordSchema,
} from "../../utils/formValidations";
import {
  resetPassword,
  validatePassword,
} from "../../store/models/auth/auth.thunk";
import { redirect, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Typography,
} from "@mui/material";
import { setInfoModal } from "../../store/models/auth/auth.slice";
import { RequestPasswordSelector } from "./RequestPassword.selector";
import { useMediaQuery } from "react-responsive";

interface IRequestPassword {}

const RequestPassword: React.FC<IRequestPassword> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  const { auth } = useAppSelector(RequestPasswordSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (
    data: any,
    { setSubmitting, setFieldError, setStatus }: any
  ) => {
    const { password, currentPassword } = data;
    setLoading(true);
    let token: string;
    if (auth) {
      token = localStorage.getItem("game-token") || "";
    } else {
      const url = window.location.pathname.split("/");
      token = url[url.length - 1];
    }

    if (currentPassword) {
      const validateResponse = await dispatch(
        validatePassword({
          password: currentPassword,
        })
      );
      //@ts-ignore
      if (validateResponse?.payload?.message === "Password not valid") {
        setFieldError("currentPassword", "Неверно указан текущий пароль");
        setSubmitting(false);
        setLoading(false);
        return;
      }
    }

    const resetResponse = await dispatch(
      resetPassword({
        password: password,
        token,
      })
    );

    // debugger;
    //@ts-ignore
    if (resetResponse?.payload?.error === "Authentification failed") {
      setFieldError(
        "password",
        "Срок обновления пароля исчерпан, пожалуйста восстановите пароль еще раз"
      );
      setFieldError("confirmPassword", " ");
      setSubmitting(false);
      setLoading(false);
      return;
    }
    //@ts-ignore
    if (resetResponse?.payload?.message === "Authorization error") {
      setFieldError(
        "password",
        "Срок обновления пароля исчерпан, пожалуйста восстановите пароль еще раз"
      );
      setFieldError("confirmPassword", " ");
      setSubmitting(false);
      setLoading(false);
      return;
    }

    //@ts-ignore
    if (resetResponse?.payload?.message === "Cannot set password to old one") {
      setFieldError(
        "password",
        "Пароль уже был использован, введите новый пароль"
      );
      setFieldError("confirmPassword", " ");
      setSubmitting(false);
      setLoading(false);
      return;
    }

    // debugger;
    setInfoMessage(true);
    setLoading(false);
  };
  const isBig = useMediaQuery({ minWidth: 1599 });
  return (
    <Container
      className="password-container container"
      maxWidth={false}
      disableGutters
      sx={{
        marginTop: { sm: "84px", xs: "112px" },
        maxWidth: "500px",
      }}
    >
      <Box className="container-box">
        <Typography
          className="primary-text-color"
          align="center"
          variant="h6"
          style={{ color: "rgba(255, 255, 255, 1)", margin: "16px 0" }}
        >
          ИЗМЕНИТЬ ПАРОЛЬ
        </Typography>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            currentPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={
            auth ? RequestPasswordAuthSchema : RequestPasswordSchema
          }
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form className="password-form">
              {auth && (
                <Box
                  className="primary-text-color"
                  style={{ position: "relative" }}
                >
                  <TextField
                    error={Boolean(
                      errors.currentPassword && touched.currentPassword
                    )}
                    className="primary-text-color"
                    size="small"
                    margin="normal"
                    fullWidth
                    id="currentPassword"
                    label="Текущий пароль"
                    name="currentPassword"
                    autoComplete="off"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                    InputProps={
                      errors.currentPassword && touched.currentPassword
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <ErrorOutlineOutlinedIcon
                                  style={{
                                    color: "rgba(223, 0, 67, 1)",
                                    fontWeight: 700,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }
                        : undefined
                    }
                  />
                  {errors.currentPassword && touched.currentPassword ? (
                    <Typography
                      className="Typography-error"
                      component="p"
                      variant="body2"
                    >
                      {errors.currentPassword}
                    </Typography>
                  ) : null}
                </Box>
              )}
              <Box style={{ position: "relative" }}>
                <TextField
                  error={Boolean(errors.password && touched.password)}
                  size="small"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Новый пароль"
                  name="password"
                  autoComplete="off"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  InputProps={
                    errors.password && touched.password
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <ErrorOutlineOutlinedIcon
                                style={{
                                  color: "rgba(223, 0, 67, 1)",
                                  fontWeight: 700,
                                }}
                              />
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
                {errors.password && touched.password ? (
                  <Typography
                    className="Typography-error"
                    component="p"
                    variant="body2"
                  >
                    {errors.password}
                  </Typography>
                ) : null}
              </Box>
              <Box style={{ position: "relative" }}>
                <TextField
                  error={Boolean(
                    errors.confirmPassword && touched.confirmPassword
                  )}
                  size="small"
                  margin="normal"
                  fullWidth
                  id="confirmPassword"
                  label="Повторите новый пароль"
                  name="confirmPassword"
                  autoComplete="off"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  InputProps={
                    errors.confirmPassword && touched.confirmPassword
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <ErrorOutlineOutlinedIcon
                                style={{
                                  color: "rgba(223, 0, 67, 1)",
                                  fontWeight: 700,
                                }}
                              />
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <Typography
                    className="Typography-error"
                    component="p"
                    variant="body2"
                  >
                    {errors.confirmPassword}
                  </Typography>
                ) : null}
              </Box>
              <Button
                style={{ width: "auto" }}
                disabled={loading}
                className={"Button" + ` Button-secondary`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? (
                  <CircularProgress
                    style={{
                      height: "25px",
                      width: "25px",
                      color: "rgb(223, 0, 67)",
                    }}
                  />
                ) : (
                  "Изменить пароль"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <Dialog
        open={infoMessage}
        onClose={() => setInfoMessage(false)}
        className="game-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ваш пароль успешно изменен
        </DialogTitle>
        <DialogActions>
          <Button
            className={"Button" + ` Button-secondary`}
            onClick={() => {
              setInfoMessage(false);
              navigate("/");
            }}
            // fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Играть
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RequestPassword;

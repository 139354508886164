import { Container } from "@mui/material";
import React from "react";

type IGameFrameProps = {
  game_url: string;
  is_demo: boolean;
  user_id: string;
  currency: string;
};

export const GameFrame = ({
  game_url,
  is_demo,
  user_id,
  currency,
}: IGameFrameProps) => {
  return (
    <Container maxWidth={false} disableGutters>
      <iframe
        title={game_url}
        src={
          is_demo
            ? `https://int.apiforb2b.com/games/${game_url}?operator_id=0&currency=&user_id=0&auth_token=`
            : `https://int.apiforb2b.com/games/${game_url}?operator_id=${
                process.env.REACT_APP_B2B_OPERATOR_ID
              }&user_id=${user_id}&auth_token=${localStorage.getItem(
                "game-token"
              )}&currency=${currency}&language=RU`
        }
        style={{
          height: `calc(100vh - 100px)`,
          width: "100%",
          border: "none",
        }}
      />
    </Container>
  );
};

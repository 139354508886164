import { Box } from "@mui/material";
import React from "react";
import { CircleProgress } from "../../CircleProgress/circle.progress";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

interface IActiveBonusInfoProps {
  id: number;
  type: string;
  bonus_user_fs_type: {
    bonus_amount: string | null;
    status: string;
    wagered: string;
    wager: string;
  } | null;
  bonus_user_pers_type: {
    bonus_amount: string | null;
    status: string;
    wagered: string;
    wager: string;
  } | null;
  days_left: number;
  min_dep: string;
  game: string | undefined;
  cancelBonus?: (bonus_id: number) => void;
}

export const ActiveBonusInfo = ({
  id,
  type,
  bonus_user_fs_type,
  bonus_user_pers_type,
  days_left,
  min_dep,
  game,
  cancelBonus,
}: IActiveBonusInfoProps) => {
  return (
    <Box className="my_activated_bonus__info__container">
      <Box className="my_activated_bonus__info__bonus-type">
        <span>Тип бонуса: </span>
        {type === "cashback"
          ? "Кэшбек"
          : bonus_user_fs_type && bonus_user_pers_type
          ? "Бонус к депозиту и фриспины"
          : bonus_user_fs_type
          ? "Фриспины"
          : "Бонус к депозиту"}
      </Box>
      <Box className="my_activated_bonus__info__bonus-status">
        <span>Статус бонуса: </span>
        {bonus_user_fs_type && bonus_user_pers_type
          ? bonus_user_pers_type.status === "active"
            ? `Ожидает депозит от ${min_dep}`
            : bonus_user_pers_type.status === "wagering" ||
              bonus_user_pers_type.status === "deposit" ||
              bonus_user_fs_type.status === "wagering" ||
              bonus_user_fs_type.status === "deposit"
            ? "Отыгрывается"
            : "Завершен"
          : bonus_user_pers_type
          ? bonus_user_pers_type.status === "active"
            ? `Ожидает депозит от ${min_dep}`
            : bonus_user_pers_type.status === "wagering" ||
              bonus_user_pers_type.status === "deposit"
            ? "Отыгрывается"
            : "Завершен"
          : bonus_user_fs_type
          ? bonus_user_fs_type.status === "active"
            ? `Ожидает депозит от ${min_dep}`
            : bonus_user_fs_type.status === "wagering" ||
              bonus_user_fs_type.status === "deposit"
            ? "Отыгрывается"
            : "Завершен"
          : null}
      </Box>
      <Box className="my_activated_bonus__info__bonus-time">
        <span>Срок действия: </span>
        {days_left < 0
          ? "Завершен"
          : days_left === 0
          ? "Последний день"
          : days_left === 1
          ? `Остался ${days_left} день`
          : days_left === 2 || days_left === 3 || days_left === 4
          ? `Осталось ${days_left} дня`
          : `Осталось ${days_left} дней`}
      </Box>
      <Box className="my_activated_bonus__info__bonus-wager">
        {bonus_user_pers_type && bonus_user_fs_type ? (
          <>
            <Box className="my_activated_bonus__info__bonus">
              <Box className="my_activated_bonus__info__bonus-title">
                Отыгрыш фриспинов
              </Box>
              <CircleProgress
                percents={
                  Math.floor(
                    (+bonus_user_fs_type.wagered / +bonus_user_fs_type.wager) *
                      100
                  ) || 0
                }
              />
              <Box className="my_activated_bonus__info__bonus-description">
                <span>Осталось отыграть: </span>{" "}
                {+bonus_user_fs_type.wager - +bonus_user_fs_type.wagered}
                <Box sx={{ textAlign: "center" }}>
                  {bonus_user_fs_type.status === "deposit" && game && (
                    <Link to={`/slots/${game}.game`}>Перейти в игру</Link>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="my_activated_bonus__info__bonus">
              <Box className="my_activated_bonus__info__bonus-title">
                Отыгрыш бонуса к депозиту
              </Box>
              <CircleProgress
                percents={
                  Math.floor(
                    (+bonus_user_pers_type.wagered /
                      +bonus_user_pers_type.wager) *
                      100
                  ) || 0
                }
              />
              <Box className="my_activated_bonus__info__bonus-description">
                <span>Осталось отыграть: </span>{" "}
                {+bonus_user_pers_type.wager - +bonus_user_pers_type.wagered}
              </Box>
            </Box>
          </>
        ) : bonus_user_pers_type ? (
          <Box className="my_activated_bonus__info__bonus">
            <Box className="my_activated_bonus__info__bonus-title">
              Отыгрыш бонуса к депозиту
            </Box>
            <CircleProgress
              percents={
                Math.floor(
                  (+bonus_user_pers_type.wagered /
                    +bonus_user_pers_type.wager) *
                    100
                ) || 0
              }
            />
            <Box className="my_activated_bonus__info__bonus-description">
              <span>Осталось отыграть: </span>{" "}
              {+bonus_user_pers_type.wager - +bonus_user_pers_type.wagered}
            </Box>
          </Box>
        ) : (
          <Box className="my_activated_bonus__info__bonus">
            <Box className="my_activated_bonus__info__bonus-title">
              Отыгрыш фриспинов
            </Box>
            <CircleProgress
              percents={
                Math.floor(
                  //@ts-ignore
                  (+bonus_user_fs_type.wagered / +bonus_user_fs_type.wager) *
                    100
                ) || 0
              }
            />
            <Box className="my_activated_bonus__info__bonus-description">
              <span>Осталось отыграть: </span>{" "}
              {
                //@ts-ignore
                +bonus_user_fs_type.wager - +bonus_user_fs_type.wagered
              }
              {
                //@ts-ignore
                bonus_user_fs_type.status === "deposit" && game && (
                  <Link to={`/slots/${game}.game`}>Перейти в игру</Link>
                )
              }
            </Box>
          </Box>
        )}
      </Box>
      {cancelBonus && (
        <Box
          className="my_activated_bonus__info__bonus-delete"
          onClick={() => {
            cancelBonus(id);
          }}
        >
          <DeleteIcon />
        </Box>
      )}
    </Box>
  );
};

import React from "react";
import { Box } from "@mui/material";
import visa from "../../../assets/payments/visa.svg";
import sbp from "../../../assets/payments/sbp.svg";
import mastercard from "../../../assets/payments/master.svg";
import bitcoin from "../../../assets/payments/bitcoin.svg";
import "./FooterPayments.css";
import { useTranslation } from "react-i18next";

export const FooterPayments = ({ slider }: { slider: boolean }) => {
  const { t } = useTranslation();

  return (
    <Box className="payments">
      {!slider && <h3 className="payments__heading">{t("footer.methods")}</h3>}

      <Box className="payments__image-container">
        <img className="payments__image" src={sbp} alt="SPB" />
        <img className="payments__image" src={visa} alt="Visa" />
        <img className="payments__image" src={mastercard} alt="Mastercard" />
        <img className="payments__image" src={bitcoin} alt="bitcoin" />
      </Box>
    </Box>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import reducers from "./payment.reducers";
import { IPayment } from "./typing/payment.interface";

const initialState: IPayment = {
  paymentMethods: { methods: [], paymentLoading: false, message: [] },
  paymentHistory: {
    deposits: {
      payments: [],
      count: 0,
      page: 0,
    },
    withdrawals: {
      payments: [],
      count: 0,
      page: 0,
    },
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers,
});

// Action creators are generated for each case reducer function
export const {
  setDepositHistory,
  setWithdrawalHistory,
  setPaymentMethods,
  setPaymentLoading,
  setPaymentMessage,
} = paymentSlice.actions;

export default paymentSlice.reducer;

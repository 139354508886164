import React from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./GameHeader.css";
import { useMediaQuery } from "react-responsive";

interface IGameHeaderProps {
  demo: boolean;
  auth: boolean;
  backHandler: () => void;
  depositHandler: () => void;
  registrationHandler: () => void;
}

export const GameHeader = ({
  backHandler,
  depositHandler,
  registrationHandler,
  demo,
  auth,
}: IGameHeaderProps) => {
  const { t } = useTranslation();
  //console.log("first");
  const isBig = useMediaQuery({ minWidth: 1599 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <>
      {!isMobile && (
        <Box className="game-header">
          <Container
            className="game-header__container container"
            maxWidth={false}
            disableGutters
          >
            <button
              type="button"
              className="game-header__button back"
              onClick={backHandler}
            ></button>

            {demo && !auth ? (
              <button
                type="button"
                className="game-header__button deposit"
                onClick={registrationHandler}
              >
                {t("buttons.registration")}
              </button>
            ) : (
              <button
                type="button"
                className="game-header__button deposit"
                onClick={depositHandler}
              >
                {t("buttons.deposit")}
              </button>
            )}
          </Container>
        </Box>
      )}

      {isMobile && (
        <Box className="game-header">
          <Container
            className="game-header__container container"
            maxWidth={false}
            disableGutters
          >
            <button
              type="button"
              className="game-header__button back"
              onClick={backHandler}
            ></button>

            {demo ? (
              <button
                type="button"
                className="game-header__button deposit"
                onClick={registrationHandler}
              >
                {t("buttons.registration")}
              </button>
            ) : (
              <button
                type="button"
                className="game-header__button deposit"
                onClick={depositHandler}
              >
                {t("buttons.deposit")}
              </button>
            )}
          </Container>
        </Box>
      )}
    </>
  );
};

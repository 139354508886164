import axios, { AxiosResponse } from "axios";
import { API_URL } from "../..";
import { RequestService } from "../../store/models/request/request.service";
import { IUser } from "../../store/models/user/typing/user.interface";
// import API, { API_URL } from "../fetch";
import { PartnerService } from "../partner/partner.service";
import {
  IActivationEmailResponce,
  IAuthService,
  ILoginResponse,
  ILogoutResponce,
  IRegistrationResponse,
  IResetLinkResponse,
  IVerifyResponse,
  IResetPasswordResponse,
  IValidatePasswordResponse,
} from "./typing/auth.interface";
import {
  TRegistration,
  TLogout,
  IActivationEmail,
  IResetLink,
  IResetPassword,
  IVerifyUser,
  TLogin,
  ValidatePassword,
  TLoginGoogle,
  TCheckWithGoogle,
} from "./typing/auth.type";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export class AuthService implements IAuthService {
  public loginWithGoogle: TLoginGoogle = async (googleResponse, promocod) => {
    const request = new RequestService();

    let regParams;
    if (!!promocod) {
    } else {
      regParams = localStorage.getItem("regkey");
    }
    const url = request.prepareUrl(`${API_URL}/api/auth/login/google`);

    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;

    return await request.run<ILoginResponse>(url, {
      method: "POST",
      withCredentials: true,
      data: {
        email: googleResponse.email,
        promocod: promocod,
        partner: regParams,
        visitor: visitorId,
      },
    });
  };
  public loginWithYandex = async () => {
    const request = new RequestService();
    const url = request.prepareUrl(`${API_URL}/api/auth/login/yandex/callback`);

    return await request.run<ILoginResponse>(url, {
      method: "POST",
      withCredentials: true,
    });
  };
  public login: TLogin = async (email, password, token, promo_code) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/login`);

    return await request.run<ILoginResponse>(url, {
      method: "POST",
      withCredentials: true,
      data: {
        email,
        password,
        token,
        promo_code,
      },
    });
  };
  public registration: TRegistration = async (
    email,
    password,
    promocod,
    token
  ) => {
    const request = new RequestService();
    // const partner = new PartnerService();

    const url = request.prepareUrl(`${API_URL}/api/auth/register`);
    let regParams;

    if (!!promocod) {
    } else {
      regParams = localStorage.getItem("regkey");
    }

    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;

    return await request.run<ILoginResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        email,
        password,
        promocod: promocod || null,
        partner: regParams || null,
        token,
        visitor: visitorId,
      },
    });
  };
  public logout: TLogout = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/logout`);

    return await request.run<ILogoutResponce>(url, {
      withCredentials: true,
      // headers: {
      //   "Access-Control-Allow-Credentials": "true",
      // },
    });
  };
  public activationEmail: IActivationEmail = async (id) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/activate/${id}`);

    return await request.run<IActivationEmailResponce>(url);
  };
  public resetLink: IResetLink = async (email) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/request`);

    return await request.run<IResetLinkResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        email,
      },
    });
  };
  public resetPassword: IResetPassword = async (password, token) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/reset`);

    return await request.run<IResetPasswordResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        password,
      },
    });
  };
  public verifyUser: IVerifyUser = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/`);

    return await request.run<ILoginResponse>(url, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
    // return API.get<IVerifyResponse>("/api/auth/", { withCredentials: true });
  };
  public validatePassword: ValidatePassword = async (password) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/auth/validatepassword`);

    return await request.run<IValidatePasswordResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        password,
      },
    });
  };
}

import { useMemo } from "react";
import { createSelector } from "reselect";
import { deposit_payment_methods } from "../../../../constants/Constants";
import { RootState } from "../../../../store/rootStore";
// import { depayments_methods } from "./Methods";

// Select the raw data from the Redux state
const UserWallets = (state: RootState) => state.user.wallets;
const Payments = (state: RootState) => state.payments;

// Create a memoized selector using reselect
export const profileDepositSelector = createSelector(
  [UserWallets, Payments],
  (userWallet, payments) => {
    const activeWallet = userWallet.filter((wallet) => wallet.active == true);

    const methods = payments.paymentMethods.methods.filter(
      (method) =>
        method.currency === activeWallet[0].currency.name &&
        method.type === "refill"
    );

    const paymentLoading = payments.paymentMethods.paymentLoading;

    const info: {
      message: string;
      type: "info" | "error" | "success" | "warning" | undefined;
    } = { message: "", type: "info" };

    // debugger;

    const reasonsMap: Record<string, string> = {
      "Payments not found":
        "К сожалению, платежные методы находяться на временном техническом обслуживании. Приносим свои извинения за временные неудобства",
      "Method not available for the user country.":
        "Некоторые методы недоступны в вашей стране. Если у вас включен VPN, попробуйте отключить его",
      "Recent unsuccessful attempts block this method.":
        "Некоторые методы временно недоступны из-за недавних неудачных попыток",
    };
    const formatReasons = (reasons: string[]): string => {
      return reasons.map((reason) => reasonsMap[reason]).join(" ");
    };

    if (payments.paymentMethods.message.length > 0) {
      info.message = formatReasons(payments.paymentMethods.message);
      info.type = "warning";
    }

    return {
      paymentMethods: methods,
      paymentLoading,
      info,
    };
  }
);

import React, { useState } from "react";
import { GameFrame } from "../Frame/frame";
import { Box, Typography } from "@mui/material";
import MButton from "../../Button/Button";
import { useNavigate } from "react-router-dom";
type IGameTypeProviderProps = {
  activeBonus: boolean;
  chooseGameType: "real" | "bonus" | undefined;
  setChooseGameType: React.Dispatch<
    React.SetStateAction<"real" | "bonus" | undefined>
  >;
  gameUrl: string;
  isDemo: boolean;
  userId: string;
  currency: string;
};
export const GameTypeProvider = ({
  activeBonus,
  chooseGameType,
  gameUrl,
  isDemo,
  setChooseGameType,
  userId,
  currency,
}: IGameTypeProviderProps) => {
  const navigate = useNavigate();
  const [bonRules, setBonRules] = useState(
    localStorage.getItem("bon_rules") === "approved"
  );
  if (isDemo) {
    return (
      <GameFrame
        game_url={gameUrl}
        is_demo={isDemo}
        user_id={userId}
        currency={currency}
      />
    );
  }
  if (activeBonus && chooseGameType === undefined) {
    return (
      <Box sx={{ height: "calc(100vh - 100px)", position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "400px",
              borderRadius: "var(--primary-border-radius)",
              backgroundColor: "var(--primary-background-color)",
              padding: { xs: "12px 18px", sm: "24px 48px" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "var(--primary-text-color)",
                marginBottom: "18px",
              }}
            >
              Выберите баланс
            </Typography>
            <Typography
              sx={{
                textAlign: "justify",
                color: "var(--secondary-text-color)",
                marginBottom: "36px",
              }}
            >
              Перед тем как начать игру, выберите баланс, на котором хотите
              играть:
              <br />
              <strong style={{ color: "var(--primary-color)" }}>
                Основной баланс
              </strong>{" "}
              — игра без бонусов, в валюте вашего кошелька (
              <strong style={{ color: "var(--primary-color)" }}>
                {currency}
              </strong>
              ).
              <br />
              <strong style={{ color: "var(--primary-color)" }}>
                Бонусный баланс
              </strong>{" "}
              — игра с бонусами.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <MButton
                text={"Основной"}
                styling={"primary"}
                onClick={() => {
                  setChooseGameType("real");
                }}
              />
              <MButton
                text={"Бонусный"}
                styling={"secondary"}
                onClick={() => {
                  setChooseGameType("bonus");
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  if (activeBonus && chooseGameType === "real") {
    return (
      <GameFrame
        game_url={gameUrl}
        is_demo={isDemo}
        user_id={userId}
        currency={currency}
      />
    );
  }
  if (activeBonus && chooseGameType === "bonus") {
    if (bonRules) {
      return (
        <GameFrame
          game_url={gameUrl}
          is_demo={isDemo}
          user_id={userId}
          currency={"BON"}
        />
      );
    }
    return (
      <Box sx={{ height: "calc(100vh - 100px)", position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "400px",
              borderRadius: "var(--primary-border-radius)",
              backgroundColor: "var(--primary-background-color)",
              padding: { xs: "12px 18px", sm: "24px 48px" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "var(--primary-text-color)",
                marginBottom: "18px",
              }}
            >
              Особенности игры
            </Typography>
            <Typography
              sx={{
                textAlign: "justify",
                color: "var(--secondary-text-color)",
                marginBottom: "36px",
              }}
            >
              Игра на бонус будет проходить с использованием бонусной валюты{" "}
              <strong style={{ color: "var(--primary-color)" }}>BON</strong>.
              Как только вы выполните условия отыгрыша,{" "}
              <strong style={{ color: "var(--primary-color)" }}>
                бонусные средства и резервированные
              </strong>{" "}
              автоматически переведутся на основной баланс в соотношении{" "}
              <strong style={{ color: "var(--primary-color)" }}>1:1</strong>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <MButton
                text={"Назад"}
                styling={"primary"}
                onClick={() => {
                  navigate("/");
                }}
              />
              <MButton
                text={"Принять и продолжить"}
                styling={"secondary"}
                onClick={() => {
                  localStorage.setItem("bon_rules", "approved");
                  setBonRules(true);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  if (!activeBonus) {
    return (
      <GameFrame
        game_url={gameUrl}
        is_demo={isDemo}
        user_id={userId}
        currency={currency}
      />
    );
  }
  return null;
};

import { createSlice } from "@reduxjs/toolkit";
import { ISlots } from "./typing/slot.interface";
import reducers from "./slot.reducers";

export const initialStateSlots: ISlots = {
  providers: [],
  games: [],
  page: 1,
  gamesCount: 0,
  isFiltered: false,
  searchGame: null,
  selectProvider: null,
  filters: [
    {
      main: true,
      categorie: true,
      active: false,
      type: "popular",
      name: "Популярные",
      action: "filterByPopularity",
      id: 1,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "recommended",
      name: "Рекомендуемые",
      action: "filterByRecommended",
      id: 9,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "buy_fs",
      name: "Купи спины",
      action: "filterByBuyFS",
      id: 6,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "new",
      name: "Новинки",
      action: "filterByNew",
      id: 3,
      elementType: "Button",
    },
    // {
    //   main: true,
    //   categorie: true,
    //   active: false,
    //   type: "roulette",
    //   name: "Рулетка",
    //   action: "filterByRoulette",
    //   id: 13,
    //   elementType: "Button",
    // },
    // {
    //   main: true,
    //   categorie: true,
    //   active: false,
    //   type: "blackjack",
    //   name: "Блэкджек",
    //   action: "filterByBlackjack",
    //   id: 14,
    //   elementType: "Button",
    // },
    // {
    //   main: true,
    //   categorie: true,
    //   active: false,
    //   type: "tables",
    //   name: "Столы",
    //   action: "filterByTables",
    //   id: 15,
    //   elementType: "Button",
    // },
    {
      main: true,
      categorie: true,
      active: false,
      type: "fruits",
      name: "Фрукты",
      action: "filterByFruits",
      id: 7,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "animals",
      name: "Животные",
      action: "filterByAnimals",
      id: 4,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "books",
      name: "Книги",
      action: "filterByBooks",
      id: 5,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "seven",
      name: "777",
      action: "filterBySevens",
      id: 8,
      elementType: "Button",
    },
    {
      main: true,
      categorie: true,
      active: false,
      type: "all",
      name: "Все слоты",
      action: "filterBySlots",
      id: 2,
      elementType: "Button",
    },
    {
      main: true,
      categorie: false,
      active: false,
      type: "providers",
      name: "Провайдеры",
      action: "filterByProvider",
      id: 11,
      elementType: "MenuItem",
    },
    {
      main: true,
      categorie: false,
      active: false,
      type: "gameName",
      name: "Название",
      action: "searchName",
      id: 12,
      elementType: "TextField",
    },
  ],
  isDemo: false,
};

export const slotsSlice = createSlice({
  name: "slots",
  initialState: initialStateSlots,
  reducers,
});

export const {
  setDemo,
  setSlots,
  setProvider,
  updateSlots,
  clearSlots,
  setPage,
  setFilter,
  findGame,
  clearFilter,
} = slotsSlice.actions;

export default slotsSlice.reducer;

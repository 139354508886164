import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type ICloseModalProps = {
  open: boolean;
  close: () => void;
  back: () => void;
};

export const CloseModal = ({ open, close, back }: ICloseModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={close}
      className="game-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("slots.exit.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("slots.exit.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={"Button Button-secondary"} onClick={back}>
          {t("buttons.yes")}
        </Button>
        <Button
          className={"Button Button-primary game-dialog-action secondary-hover"}
          onClick={close}
        >
          {t("buttons.no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

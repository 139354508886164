import React, { useCallback, useEffect, useState } from "react";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import { Box, Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./ProfilePaymentHistoryDeposits.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { ProfilePaymentHistorySelector } from "./selector/ProfilePaymentHistory.selector";
import {
  createWithdrawalRequest,
  getDepositHistory,
  getWithdrawalHistory,
} from "../../../../store/models/payment/payment.thunk";
import { useDispatch } from "react-redux";
import { IPaymentHistory } from "../../../../store/models/payment/typing/payment.interface";

export const ProfilePaymentHistory = () => {
  const { t } = useTranslation();
  const [type, setType] = useState<"refill" | "withdrawals">("refill");
  const switchHandler = (value: "refill" | "withdrawals") => {
    if (value === "refill") {
      setType(value);
    } else {
      setType(value);
    }
  };
  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "../", text: t("main.profile.links.deposit") },
        { link: "../withdrawal", text: t("main.profile.links.withdrawal") },
        { link: "", text: t("main.profile.links.history") },
      ]}
    >
      <Box className="profile-paymenthistory">
        <Box className="profile-paymenthistory__container">
          <ProfilePaymentHistoryHeader
            type={type}
            switchHandler={switchHandler}
          />
          {type === "refill" ? (
            <ProfilePaymentHistoryDeposits />
          ) : (
            <ProfilePaymentHistoryWithdrawals />
          )}
        </Box>
      </Box>
    </ProfileContentContainer>
  );
};

type IProfilePaymentHistoryHeaderProps = {
  type: "refill" | "withdrawals";
  switchHandler: (value: "refill" | "withdrawals") => void;
};

export const ProfilePaymentHistoryHeader = ({
  type,
  switchHandler,
}: IProfilePaymentHistoryHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Box className="profile-paymenthistory__header">
      <Box
        className={
          type === "refill"
            ? "profile-paymenthistory__link active"
            : "profile-paymenthistory__link"
        }
        onClick={() => switchHandler("refill")}
      >
        {t("main.profile.payments.history.columns.deposits")}
        <span></span>
      </Box>
      <Box
        className={
          type === "withdrawals"
            ? "profile-paymenthistory__link active"
            : "profile-paymenthistory__link"
        }
        onClick={() => switchHandler("withdrawals")}
      >
        {t("main.profile.payments.history.columns.withdrawals")}
        <span></span>
      </Box>
    </Box>
  );
};

export const ProfilePaymentHistoryDeposits = () => {
  const { t } = useTranslation();
  const { deposits } = useAppSelector(ProfilePaymentHistorySelector);
  const [page, setPage] = React.useState(deposits.page);
  const showMoreHandler = () => {
    setPage(page + 1);
  };
  const disabled = (function () {
    if (page + 1 < deposits.count / 10) {
      return false;
    } else {
      return true;
    }
  })();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDepositHistory({ page: page }));
  }, [page, deposits.count]);
  return (
    <>
      <Box className="profile-paymenthistory__main">
        {deposits.payments.map((payment, index) => {
          const date = new Date(+payment.payment_date).toLocaleString("ru", {
            day: "2-digit",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return (
            <Box className="profile-paymenthistory__payment" key={payment.id}>
              <Box className="profile-paymenthistory__payment_date">
                {`${date} - Депозит`}
              </Box>
              <Box className="profile-paymenthistory__payment_amount">
                {`${(+payment.amount).toFixed(2)} ${
                  payment.currency === "RUB"
                    ? "₽"
                    : payment.currency === "USD"
                    ? "$"
                    : "€"
                }`}
              </Box>
              <Box className="profile-paymenthistory__payment_card">
                {`${
                  !payment.credentials
                    ? "****************"
                    : payment.credentials
                }`}
              </Box>
              {payment.status === "pending" ? (
                <Box className="profile-paymenthistory__payment_status pending">
                  <AccessTimeIcon /> Обработка
                  {payment.paymentUrl && (
                    <Button
                      style={{ margin: " 0 9px", padding: "6px 23px" }}
                      className="Button Button-secondary primary-hover"
                    >
                      <Link className="payment__link" href={payment.paymentUrl}>
                        Оплатить
                      </Link>
                    </Button>
                  )}
                </Box>
              ) : payment.status === "success" ? (
                <Box className="profile-paymenthistory__payment_status success">
                  <CheckCircleOutlineRoundedIcon /> Одобрено
                </Box>
              ) : payment.status === "rejected" ||
                payment.status === "error" ? (
                <Box className="profile-paymenthistory__payment_status rejected">
                  <ErrorOutlineRoundedIcon />
                  Отклонена
                </Box>
              ) : (
                ""
              )}
            </Box>
          );
        })}
      </Box>

      <Box className="profile-paymenthistory__footer">
        <button
          type="button"
          className="profile-paymenthistory__footer-button"
          onClick={showMoreHandler}
          disabled={disabled}
        >
          {t("main.profile.payments.history.button.more")}
        </button>
      </Box>
    </>
  );
};
export const ProfilePaymentHistoryWithdrawals = () => {
  const { t } = useTranslation();
  const { withdrawals } = useAppSelector(ProfilePaymentHistorySelector);
  const [page, setPage] = React.useState(withdrawals.page);
  const showMoreHandler = () => {
    setPage(page + 1);
  };
  const disabled = (function () {
    if (page + 1 < withdrawals.count / 10) {
      return false;
    } else {
      return true;
    }
  })();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (page === withdrawals.page) {
      dispatch(getWithdrawalHistory({ page: page }));
    }
  }, [page, withdrawals.count]);
  return (
    <>
      <Box className="profile-paymenthistory__main">
        {withdrawals.payments.map((payment: IPaymentHistory, index: number) => {
          const date = new Date(+payment.payment_date).toLocaleString("ru", {
            day: "2-digit",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return (
            <ProfilePaymentHistoryPayment
              date={date}
              id={payment.id}
              currency={payment.currency}
              comment={payment.comment}
              amount={(+payment.amount).toFixed(2)}
              credentials={payment.credentials}
              status={payment.status}
            />
          );
        })}
      </Box>
      <Box className="profile-paymenthistory__footer">
        <button
          type="button"
          className="profile-paymenthistory__footer-button"
          onClick={showMoreHandler}
          disabled={disabled}
        >
          {t("main.profile.payments.history.button.more")}
        </button>
      </Box>
    </>
  );
};

type IProfilePaymentHistoryPayment = {
  id: string;
  date: string;
  amount: string;
  currency: string;
  credentials: string | null;
  status: string;
  comment?: string;
};

export const ProfilePaymentHistoryPayment = ({
  id,
  date,
  amount,
  currency,
  credentials,
  status,
  comment,
}: IProfilePaymentHistoryPayment) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const dispatch = useAppDispatch();
  const openHandler = () => {
    setOpen(!open);
  };

  const cancelWithdrawal = async () => {
    setLoading(true);
    const response = await dispatch(createWithdrawalRequest({ id: id }));
    if (response.payload === "ok") {
      setCurrentStatus("rejected");
      setLoading(false);
    }
  };

  return (
    <Box className="profile-paymenthistory__payment">
      <Box className="profile-paymenthistory__payment_date">
        {`${date} - Вывод`}
      </Box>
      <Box className="profile-paymenthistory__payment_amount">
        {`${amount} ${currency}`}
      </Box>
      <Box className="profile-paymenthistory__payment_card">
        {`${!credentials ? "****************" : credentials}`}
      </Box>

      {currentStatus === "pending" ? (
        <Box className="profile-paymenthistory__payment_status pending">
          <AccessTimeIcon /> В очереди
          <Button
            style={{ margin: " 0 9px", padding: "6px 23px" }}
            className="Button Button-secondary primary-hover"
            disabled={loading}
          >
            <Link className="payment__link" onClick={cancelWithdrawal}>
              Отменить
            </Link>
          </Button>
        </Box>
      ) : currentStatus === "approved" ? (
        <Box className="profile-paymenthistory__payment_status approved">
          <AccessTimeIcon /> Одобрена
          <Button
            style={{ margin: " 0 9px", padding: "6px 23px" }}
            className="Button Button-secondary primary-hover"
          ></Button>
        </Box>
      ) : currentStatus === "success" ? (
        <Box className="profile-paymenthistory__payment_status success">
          <CheckCircleOutlineRoundedIcon /> Обработана
        </Box>
      ) : currentStatus === "rejected" ? (
        <>
          <Box
            className={
              open
                ? "profile-paymenthistory__payment_box rejected open"
                : "profile-paymenthistory__payment_box rejected"
            }
            onClick={openHandler}
          >
            <Box className="profile-paymenthistory__payment_status">
              <ErrorOutlineRoundedIcon />
              Отклонена
              {comment ? (
                <KeyboardArrowDownRoundedIcon className="profile-paymenthistory__payment_arrow" />
              ) : (
                <></>
              )}
            </Box>
            {comment ? (
              <Box className="profile-paymenthistory__payment_comment">
                {comment}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

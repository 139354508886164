import React, { useEffect, useState } from "react";
import "./providerSelect.css";
import { IStoreProvider } from "../../../store/models/slots/typing/slot.interface";
import { Box, Container, InputBase, Paper, Portal } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";

type IProviderSelectProps = {
  data: IStoreProvider[];
  onClick: (providerId: number) => void;
  isMain?: boolean;
};

export const ProviderSelect = ({
  data,
  onClick,
  isMain,
}: IProviderSelectProps) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isBig = useMediaQuery({ minWidth: 1599 });
  const [active, setActive] = useState(false);
  const [copy, setCopy] = useState<
    {
      id: number;
      firstLetter: string;
      providers: IStoreProvider[];
    }[]
  >([]);
  const [input, setInput] = useState("");
  const openHandler = () => {
    setActive(true);
    if (isMobile) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
  };
  const closeHandler = () => {
    setActive(false);
    if (isMobile) {
      document.getElementsByTagName("body")[0].style.overflow = "";
    }
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCopy((prev) => {
      return prev.filter((item) => {
        return (
          item.providers.filter((provider) => {
            return provider.name
              .toLowerCase()
              .trim()
              .includes(input.toLocaleLowerCase().trim());
          }).length > 0
        );
      });
    });
  };
  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(e.target.value);
    if (e.target.value === "") {
      setCopy(copyHandler(data));
    }
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (input.trim()) {
        setCopy((prev) => {
          return prev.filter((item) => {
            return (
              item.providers.filter((provider) => {
                return provider.name
                  .toLowerCase()
                  .trim()
                  .includes(input.toLocaleLowerCase().trim());
              }).length > 0
            );
          });
        });
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [input]);
  useEffect(() => {
    setCopy(copyHandler(data));
  }, [data]);
  return (
    <>
      <div className="provider-select">
        <button className="provider-select__button" onClick={openHandler}>
          Провайдеры
        </button>
      </div>
      {active && (
        <Portal>
          <Box
            className="provider-select__portal"
            sx={{
              height: `${
                document.getElementsByTagName("body")[0].offsetHeight
              }px`,
            }}
            onClick={closeHandler}
          >
            <Container
              className="container"
              maxWidth={false}
              disableGutters
              sx={{ position: "relative" }}
            >
              <div
                className={`provider-select__container ${isMain && "main"}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {isMobile && (
                  <Box className="provider-select__title__container">
                    <h1 className="provider-select__title">Провайдеры</h1>
                    <CloseIcon
                      onClick={closeHandler}
                      className="provider-select__title__icon"
                      sx={{ fontSize: "16px" }}
                    />
                  </Box>
                )}
                <div className="provider-select__input-container">
                  <Paper
                    className="provider-select__input-form"
                    component="form"
                    onSubmit={onSubmit}
                  >
                    <SearchIcon
                      sx={{
                        fontSize: "24px",
                        position: "absolute",
                        top: "6px",
                        left: "14px",
                      }}
                    />

                    <InputBase
                      sx={{
                        width: "100%",
                        flex: 1,
                      }}
                      placeholder="Введите название"
                      inputProps={{
                        className: "provider-select__input",
                        "aria-label": "Введите название",
                      }}
                      value={input}
                      onChange={inputHandler}
                    />
                  </Paper>
                </div>
                <div className="provider-select__list">
                  {copy.map((provider) => {
                    return (
                      <div
                        key={provider.id}
                        className="provider-select__list-container"
                      >
                        <div className="provider-select__list-title">
                          {provider.firstLetter}
                        </div>
                        <div className="provider-select__list-providers">
                          {provider.providers.map((providerItem) => {
                            return (
                              <div
                                className="provider-select__list-provider-name"
                                key={providerItem.id}
                                onClick={() => {
                                  onClick(providerItem.id);
                                  closeHandler();
                                }}
                              >
                                {providerItem.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Container>
          </Box>
        </Portal>
      )}
    </>
  );
};

const copyHandler = (data: IStoreProvider[]) => {
  const firstLettersArray: {
    id: number;
    firstLetter: string;
    providers: IStoreProvider[];
  }[] = [];
  let i = 0;
  const reg = /^\d+$/;
  data.forEach((provider) => {
    const firstLetter = provider.name.slice(0, 1);
    const index = firstLettersArray.findIndex((item) =>
      reg.test(firstLetter)
        ? item.firstLetter === "0-9"
        : item.firstLetter === firstLetter
    );
    // console.log(index);
    if (index === -1) {
      firstLettersArray.push({
        id: i++,
        firstLetter: reg.test(firstLetter) ? "0-9" : firstLetter,
        providers: [provider],
      });
      return;
    }
    firstLettersArray[index].providers = [
      ...firstLettersArray[index].providers,
      provider,
    ];
  });
  // console.log(firstLettersArray);
  return firstLettersArray;
};

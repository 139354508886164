import { RootState } from "../../store/rootStore";
import { createSelector } from "reselect";

const slides = (state: RootState) => ({
  slider: state.slider,
});

export const SliderSelector = createSelector(slides, ({ slider }) => {
  return { slider };
});

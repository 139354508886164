import mastercard from "../assets/payments/master.svg";
import visa from "../assets/payments/visa.svg";
import bitcoin from "../assets/payments/bitcoin.svg";
import usdttrc20 from "../assets/payments/usdttrc20.svg";
import sbp from "../assets/payments/sbp.svg";
import betaTransfer from "../assets/payments/btr-kassa.png";

export const currencies: { value: string; label: string }[] = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  // {
  //   value: "BTC",
  //   label: "฿",
  // },
  {
    value: "RUB",
    label: "₽",
  },
];

export const language = ["RU", "EN"];

export const OPERATOR_ID = "40077";

export const IS_MOBILE =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const TABLE_HISTORY_FILTER = [
  {
    id: 1,
    type: {
      value: "currency",
      display_value: "Валюта",
    },
    values: [
      { value: "USD", display_value: "USD" },
      { value: "RUB", display_value: "RUB" },
      { value: "EUR", display_value: "EUR" },
    ],
  },
  {
    id: 2,
    type: {
      value: "status",
      display_value: "Статус",
    },
    values: [
      { value: "success", display_value: "Доставлен" },
      { value: "error", display_value: "Ошибка" },
      { value: "pending", display_value: "Ожидание" },
    ],
  },
  {
    id: 3,
    type: {
      value: "transaction",
      display_value: "Транзакция",
    },
    values: [
      { value: "withdrawals", display_value: "Вывод" },
      { value: "refill", display_value: "Депозит" },
    ],
  },
];

export const withdrawal_payment_methods = [
  {
    id: 1,
    method_id: 94,
    text: "Visa",
    image: visa,
    currency: "RUB",
    payment_system: "freekassa",
    min: 1000,
    max: 10000,
  },
  {
    id: 2,
    method_id: 94,
    text: "Master Card",
    image: mastercard,
    currency: "RUB",
    payment_system: "freekassa",
    min: 1000,
    max: 10000,
  },
  {
    id: 2,
    method_id: 94,
    text: "Master Card",
    image: mastercard,
    currency: "USD",
    payment_system: "freekassa",
    min: 100,
    max: 200,
  },
  {
    id: 2,
    method_id: 94,
    text: "Master Card",
    image: mastercard,
    currency: "EUR",
    payment_system: "",
    min: 100,
    max: 200,
  },
  // {
  //   id: 3,
  //   method_id: 201,
  //   text: "СБП",
  //   image: sbp,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 1000,
  //   max: 100000,
  // },
];

export const deposit_payment_methods = [
  // {
  //   id: 1,
  //   method_id: 4,
  //   text: "Visa",
  //   image: visa,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 50,
  //   max: 150000,
  // },
  // {
  //   id: 2,
  //   method_id: 8,
  //   text: "Master Card",
  //   image: mastercard,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 50,
  //   max: 150000,
  // },
  // {
  //   id: 9,
  //   method_id: 15,
  //   text: "USDT TRC20",
  //   image: usdttrc20,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 500,
  //   max: 10000000,
  // },

  // {
  //   id: 4,
  //   method_id: 12,
  //   text: "Мир",
  //   image: mir,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 50,
  //   max: 150000,
  // },
  // {
  //   id: 5,
  //   method_id: 6,
  //   text: "ЮMoney",
  //   image: yomoney,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 300,
  //   max: 75000,
  // },
  // {
  //   id: 6,
  //   method_id: 24,
  //   text: "Bitcoin",
  //   image: bitcoin,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 300,
  //   max: 10000000,
  // },
  // {
  //   id: 8,
  //   method_id: 42,
  //   text: "СБП",
  //   image: sbp,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 100,
  //   max: 100000,
  // },
  // {
  //   id: 3,
  //   method_id: 14,
  //   text: "USDT ERC20",
  //   image: usdt,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 1000,
  //   max: 10000000,
  // },
  // {
  //   id: 10,
  //   method_id: 1,
  //   text: "Free Kassa",
  //   image: fk,
  //   currency: "RUB",
  //   payment_system: "freekassa",
  //   min: 10,
  //   max: 300000,
  // },
  // {
  //   id: 99999,
  //   method_id: 99999,
  //   text: "BetaTransfer",
  //   image: betaTransfer,
  //   currency: "RUB",
  //   payment_system: "betatransfer",
  //   min: 1000,
  //   max: 1000000,
  // },
  {
    id: 99999,
    method_id: 99999,
    text: "BetaTransfer",
    image: betaTransfer,
    currency: "RUB",
    payment_system: "betatransfer",
    min: 1000,
    max: 1000000,
  },
];

export const repayBanks = [
  {
    bankName: "Банк АВАНГАРД",
    schema: "b9a2a338-ccd7-4bb1-8bf3-e7b626f9ac37",
    logoURL: "",
  },
  {
    bankName: "Банк Элита",
    schema: "d70ffacd-2020-4d27-851a-a823573038de",
    logoURL: "",
  },
  {
    bankName: "Банк ФК Открытие",
    schema: "b028103a-7dc9-4c60-bb2b-dcee8656b65e",
    logoURL: "",
  },
  {
    bankName: "ВТБ",
    schema: "e93488d9-d2c9-404b-85f9-b37eb35a2f99",
    logoURL: "",
  },
  {
    bankName: "СберБанк",
    schema: "a28333c4-db42-4805-b560-4db95ad50dca",
    logoURL: "",
  },
  {
    bankName: "МКБ (Московский кредитный банк)",
    schema: "6ad58e62-1f44-4f40-8843-e7359e988db7",
    logoURL: "",
  },
  {
    bankName: "Совкомбанк",
    schema: "9bcc36cb-09a1-478b-9c9d-0758aa8b806c",
    logoURL: "",
  },
  {
    bankName: "Т-Банк (Тинькофф)",
    schema: "19235c82-622e-4286-888f-2ecd25c0b009",
    logoURL: "",
  },
  {
    bankName: "Россельхозбанк",
    schema: "62381f29-0776-48c2-90ee-d7d68fe371ec",
    logoURL: "",
  },
  {
    bankName: "ЮниКредит Банк",
    schema: "32ce7bfa-f0e2-4f04-9731-31a39bcd8c0c",
    logoURL: "",
  },
  {
    bankName: "Райффайзен Банк",
    schema: "f2d25a0d-65bc-4dbe-9e98-6c3015bbd90a",
    logoURL: "",
  },
  {
    bankName: "Промсвязьбанк",
    schema: "e5cce7cb-665a-4aa3-8240-2002694869ee",
    logoURL: "",
  },
  {
    bankName: "Росбанк",
    schema: "504d81e3-55d5-4313-bf45-5eae52879150",
    logoURL: "",
  },
  {
    bankName: "Газпромбанк",
    schema: "0634762b-63a2-4b85-8e79-9d12a61874aa",
    logoURL: "",
  },
  {
    bankName: "Альфа Банк",
    schema: "691103fb-7351-4480-8a6d-03a91f1a54b5",
    logoURL: "",
  },
  {
    bankName: "Озон Банк (Ozon)",
    schema: "84a3cc4e-f94f-4081-ac5c-92420fb3e836",
    logoURL: "",
  },
  {
    bankName: "Ак Барс Банк",
    schema: "10831233-07ff-461f-9cb2-414e3a003786",
    logoURL: "",
  },
  {
    bankName: "Хоум Кредит Банк (Хоум Банк)",
    schema: "ef10f896-5d53-47b0-bbf6-5b0f8f92027e",
    logoURL: "",
  },
  {
    bankName: "МТС Банк",
    schema: "b3d8508e-82bb-4fb7-96c6-8dca6bee3c41",
    logoURL: "",
  },
  {
    bankName: "УБРиР",
    schema: "2a539b2c-b054-4c1c-95c0-ae5b098c0895",
    logoURL: "",
  },
  {
    bankName: "Яндекс Банк",
    schema: "314b2f17-b03e-4eba-9c00-e5cab25c31db",
    logoURL: "",
  },
  {
    bankName: "РНКБ Банк",
    schema: "b585dad8-a503-4b5e-a1c8-b981763accf3",
    logoURL: "",
  },
  {
    bankName: "Почта Банк",
    schema: "60fe6929-a0ac-4d2a-84d3-1f1324e871d1",
    logoURL: "",
  },
  {
    bankName: "Точка Банк",
    schema: "85445625-c9df-4d32-8c49-f84ee3e256c2",
    logoURL: "",
  },
];

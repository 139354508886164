export enum ProfileAction {
  SAVE_PERSONAL_INFO = "savePersonalInfo",
  ADD_PHONE = "addPhone",
  CHANGE_WALLET = "changeWallet",

  CREATE_PARTNER_LINK = "createPartnerLink",
  CREATE_LOCAL_PARTNER_LINK = "createLocalPartnerLink",
  REMOVE_PARTNER_LINK = "removePartnerLink",
  REMOVE_LOCAL_PARTNER_LINK = "removeLocalPartnerLink",
  GET_PARTNER_LINKS = "getPartnerLinks",
  SET_PARTNER_LINK = "setPartnerLink",
  SET_PARTNER_BONUSES = "setPartnerBonuses",
  GET_GENERAL_INFO = "setGeneralInfo",
  GET_LINKS_INFO = "setLinksInfo",
  SET_PARTNER_LINK_LIMIT = "setPartnerLinkLimit",
  SET_PARTNER_INFORMATION = "setPartnerInformation",
  SET_PARTNER_GENERAL = "setPartnerGeneral",
  SET_PARTNER_ARCHIVE = "setPartnerArchive",
  PARTNER_RESET_DATA = "partnerResetData",
}

import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { SlotCard } from "../SlotCard/SlotCard";
import { IStoreSlot } from "../../../store/models/slots/typing/slot.interface";
import MButton from "../../Button/Button";
import "./SlotCategories.css";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../store/hooks/store.hooks";
import { getTSlots } from "../../../store/models/slots/slot.thunk";
import { useInView } from "react-intersection-observer";
import { setSlots } from "../../../store/models/slots/slot.slice";

interface ISlotCategoriesProps {
  isMain: boolean;
  heading: string;
  action:
    | "filterByPopularity"
    | "filterByRecommended"
    | "filterByBuyFS"
    | "filterByNew"
    | "filterBySevens"
    | "filterByFruits"
    | "filterByAnimals"
    | "filterBySlots";
  searchGame?: string | null;
}
export const SlotCategories = ({
  isMain,
  heading,
  action,
  searchGame,
}: ISlotCategoriesProps) => {
  const [games, setGames] = useState<IStoreSlot[]>([]);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function fetch() {
      let object: {
        [key: string]: boolean | number | undefined;
        page: number;
        provider: number | undefined;
      };
      switch (action) {
        case "filterByPopularity": {
          object = { popular: true, page: 1, provider: undefined };
          break;
        }
        case "filterByRecommended": {
          object = { recommended: true, page: 1, provider: undefined };
          break;
        }
        case "filterByAnimals": {
          object = { animals: true, page: 1, provider: undefined };
          break;
        }
        case "filterByBuyFS": {
          object = { buy_fs: true, page: 1, provider: undefined };
          break;
        }
        case "filterByFruits": {
          object = { fruits: true, page: 1, provider: undefined };
          break;
        }
        case "filterByNew": {
          object = { new: true, page: 1, provider: undefined };
          break;
        }
        case "filterBySevens": {
          object = { new: true, page: 1, provider: undefined };
          break;
        }
        default: {
          object = { all: true, page: 1, provider: undefined };
          break;
        }
      }
      const slotsResponse = await dispatch(
        getTSlots(searchGame ? { ...object, name: searchGame } : object)
      );
      if (slotsResponse.type === "gettSlots/fulfilled") {
        const slots = slotsResponse.payload as {
          games: IStoreSlot[];
          count: number;
        };
        setGames(slots.games);
      }
    }
    fetch();
  }, [searchGame]);
  const moreHandler = () => {
    if (action) {
      if (action === "filterByPopularity") {
        navigator("/lobby?popular=true");
      }
      if (action === "filterByNew") {
        navigator("/lobby?new=true");
      }
      if (action === "filterBySlots") {
        navigator("/lobby");
      }
    }
  };

  return (
    <Box
      className={`slot-category ${isMain ? "slot-main" : "slot-lobby"}`}
      sx={{ display: games.length > 0 ? "block" : "none" }}
    >
      <Box className="slot-category__header">
        <Typography
          className="slot-category__heading"
          variant="subtitle1"
          component="h2"
        >
          {heading}
        </Typography>
      </Box>

      <Grid
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {games.map((game) => {
          return (
            <SlotCard
              key={`${heading}_${game.id}`}
              cardId={game.id}
              src={game.image_url}
              title={game.title}
              url={game.url}
            />
          );
        })}
      </Grid>

      <div className="slots-categories__button-container">
        <MButton
          className="slots-categories__button primary-hover"
          text={"еще игры"}
          styling={"secondary"}
          onClick={moreHandler}
        />
      </div>
    </Box>
  );
};

import {
  ISlotsFilter,
  IStoreProvider,
} from "./../../../store/models/slots/typing/slot.interface";
import { createSelector } from "reselect";
import { RootState } from "../../../store/rootStore";

interface ISlotsFilterSelector {
  filters: ISlotsFilter;
  providers: IStoreProvider[];
  isFiltered: boolean;
  gameCount: number;
  // filterByProviderHandler: (title: string) => void;
  // searchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // filterByPopulation: () => void;
  // filterByNew: () => void;
  // clearFiltersHandler: () => void;
}

const selectRawData = (state: RootState) => state.slots;

export const slotsFilterSelector = createSelector(selectRawData, (slots) => {
  const filters = slots.filters;
  // const gameCount = slots.games.length - 1;
  const providers = slots.providers;
  const isFiltered = slots.isFiltered;
  return {
    filters,
    providers,
    isFiltered,
    // gameCount,
  };
});

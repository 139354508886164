import React from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store/rootStore";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import { logout } from "./store/models/auth/auth.thunk";
import { setAuthorizationModal } from "./store/models/auth/auth.slice";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const InitApp = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <GoogleOAuthProvider
          // clientId="993839815051-jhhacanmo286b2tjpkb02mrtlq7fmnul.apps.googleusercontent.com"
          clientId={process.env.REACT_APP_GOOGLE_USER_CLIENT_ID}
        >
          <App />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

// export const API_URL = "https://enomo.online";
export const API_URL = process.env.REACT_APP_SERVER_URL;
// export const API_URL = "https://game-house-afe9318d4013.herokuapp.com";
// export const FREEKASSA_API = "https://pay.freekassa.ru";

export const API = axios.create({
  // withCredentials: true,
  // baseURL: API_URL,
  headers: {
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "http://localhost:3000",
    // "Access-Control-Allow-Headers": "*",
    // "Access-Control-Allow-Credentials": "true",
  },
});
// import store from "./store/rootStore";
const { dispatch } = store;
API.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/api/auth/refresh`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("game-token")}`,
          },
        });
        localStorage.setItem("game-token", response.data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return API.request(originalRequest);
      } catch (error) {
        // console.log("not authorized");
        dispatch(logout());
        window.location.href = "/login";
        // dispatch(setAuthorizationModal(true));
        throw error;
      }
    }
    return Promise.reject(error);
  }
);

export default API;

root.render(<InitApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import { API_URL } from "./../fetch";
import { API_URL } from "../..";
import { RequestService } from "../../store/models/request/request.service";
import {
  IPaymentService,
  IPaymentServiceCreate,
} from "./typing/payment.interface";

export class PaymentService implements IPaymentService {
  public createPayment = async ({
    amount,
    type,
    method_id,
    promocode,
    credentials,
    bank_id,
  }: IPaymentServiceCreate) => {
    const request = new RequestService();

    // debugger;
    return await request.run<any>(`${API_URL}/api/payments/create`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${localStorage.getItem("game-token")}`,

        "Content-Type": "application/json",
      },
      data: {
        amount,
        type,
        method_id,
        ...(promocode && { promocode }),
        ...(credentials && { credentials }),
        ...(bank_id && { bank_id }),
      },
    });
  };

  // public successful = async (
  //   order_id: string,
  //   payment_system: string
  // ) => {
  //   const request = new RequestService();

  //   const url = request.prepareUrl(`${API_URL}/api/payments/successful`);

  //   return await request.run<any>(url, {
  //     method: "POST",
  //     headers: {
  //       // Authorization: `Bearer ${localStorage.getItem("game-token")}`,
  //       "Content-Type": "application/json",
  //     },
  //     data: {
  //       order_id,
  //       payment_system,
  //     },
  //   });
  // };

  public failureRepay = async (order_id: string, payment_system: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/failure`);

    return await request.run<any>(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Content-Type": "application/json",
      },
      data: {
        order_id,
        payment_system,
      },
    });
  };
  public getPaymentHistory = async (page: number, filter: any) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/history`, {
      additionalQuery: {
        page,
        ...filter,
      },
    });

    // debugger;
    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };
  public validatePromocode = async (promocode: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/promocode`);

    const response = await request.run<any>(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
      data: {
        promocode,
      },
    });

    return response;
  };

  public getPaymentMethods = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/paymentmethods`);
    const response = await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });

    return response;
  };

  public verifyDeposits = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/verify-deposits`);
    return await request.run<{ deposits: boolean }>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public verifyWithrawals = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(
      `${API_URL}/api/payments/verify-withdrawals`
    );
    return await request.run<{ withdrawals: boolean }>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public cancelWithdrawal = async (id: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/cancel-withdrawal`);
    return await request.run<any>(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
      data: {
        id,
      },
    });
  };
}

import React, { useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { IBonusesListItem } from "./typing/BonusesListItem.interface";
import "./BonusesListItem.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../store/hooks/store.hooks";
import { setRegistrationModal } from "../../../store/models/auth/auth.slice";
import { activateGlobalBonus } from "../../../store/models/bonuses/bonuses.thunk";
import { bonusesListItemSelector } from "../Bonuses.selector";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BonusDialog } from "./BonusDialog/bonus.dialog";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../../loader/loader";
import { Image } from "./BonusImage/bonus.image";

const BonusesListItem: React.FC<IBonusesListItem> = ({
  id,
  bg_img,
  sub_img,
  title,
  sub_title,
  promo_code,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [infoMessage, setInfoMessage] = useState({ show: false, message: "" });
  const [infoMessageButton, setInfoMessageButton] = useState("");
  const [loading, setLoading] = useState(false);

  const { auth } = useAppSelector(bonusesListItemSelector);

  const dispatch = useAppDispatch();

  const getBonus = async () => {
    if (!auth) {
      dispatch(setRegistrationModal(true));
      return;
    }
    if (promo_code) {
      setLoading(true);

      const message: any = await dispatch(
        activateGlobalBonus({ promocode: promo_code })
      );
      if (message.payload) {
        if (
          message.payload ===
          "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
        ) {
          setInfoMessageButton(t("buttons.getverification"));
        } else if (message.payload === "Этот бонус был использован ранее") {
          setInfoMessageButton(t("buttons.close"));
        } else if (
          message.payload ===
          "Данный бонус недоступен для Вашего игрового счета"
        ) {
          setInfoMessageButton(t("buttons.close"));
        } else if (
          message.payload === "Данный бонус доступен только при регистрации"
        ) {
          setInfoMessageButton(t("buttons.close"));
        } else if (
          message.payload ===
          "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
        ) {
          setInfoMessageButton(t("buttons.shorttopup"));
        } else if (message.payload === "Бонус был успешно активирован") {
          setInfoMessageButton(t("buttons.play"));
        } else if (
          message.payload ===
          "У вас есть другой активный бонус. Завершите его и после сможете принять участие в этом"
        ) {
          setInfoMessageButton(t("buttons.play"));
        } else if (message.payload === "Bonus not found") {
          setInfoMessageButton(t("buttons.close"));
        }
      } else {
        setInfoMessageButton(t("buttons.play"));
      }
      setLoading(false);
      setInfoMessage({
        show: true,
        message: message.payload,
      });
    } else {
      setInfoMessage({ show: true, message: "Бонус не найден" });
      setInfoMessageButton(t("buttons.close"));
    }
  };

  const dialogHandler = () => {
    if (
      infoMessage.message ===
      "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
    ) {
      navigate("/profile/personal-info/verification");
    } else if (
      infoMessage.message ===
      "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
    ) {
      navigate("/profile/payments");
    } else if (infoMessage.message === "Бонус был успешно активирован") {
      navigate("/lobby");
    }
    setInfoMessage({ show: false, message: "" });
  };
  const closeDialog = () => setInfoMessage({ show: false, message: "" });
  useEffect(() => {}, [bg_img, sub_img]);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <React.Fragment>
      <Box className="bonus-card__container">
        <Box className="bonus-card__image">
          {bg_img.name ? (
            <Image
              alt={bg_img.name}
              src={`${process.env.REACT_APP_SERVER_URL}/api/bonus/image?image_name=${bg_img.name}`}
              classname="bonus-card__bg-image"
            />
          ) : (
            <Box
              className="bonus-card__bg-image"
              sx={{ backgroundColor: "var(--primary-background-color)" }}
            />
          )}
          {sub_img.name && (
            <Image
              alt={sub_img.name}
              src={`${process.env.REACT_APP_SERVER_URL}/api/bonus/image?image_name=${sub_img.name}`}
              classname="bonus-card__sub-image"
            />
          )}
        </Box>
        <Box className="bonus-card__content">
          <Box className="bonus-card__content-title">{title}</Box>
          <Box className="bonus-card__content-sub-title">{sub_title}</Box>
          <Box className="bonus-card__content-buttons">
            <Link
              to={`/bonuses/${id}`}
              className="bonus-card__content-button-rule"
            >
              Условия бонуса
            </Link>
            <button
              className="bonus-card__content-button-activate"
              onClick={getBonus}
            >
              Получить бонус
            </button>
          </Box>
        </Box>
      </Box>
      <BonusDialog
        infoMessage={infoMessage}
        loading={loading}
        infoMessageButton={infoMessageButton}
        closeDialog={closeDialog}
        dialogHandler={dialogHandler}
      />
      <Dialog
        open={loading}
        sx={{
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "transparent",
          ".MuiDialog-container": {
            alignItems: "center",
            backdropFilter: "blur(5px)",
            position: "relative",
          },
          ".MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          sx: {
            margin: isMobile ? "106px 14px 0px 14px" : "0px 14px 0px 14px",
            width: "100%",
            maxWidth: "400px",
            height: isMobile ? "200px" : "300px",
            maxHeight: "100%",
            borderRadius: "var(--primary-border-radius)",
            overflow: "hidden",
            backgroundColor: "var(--secondary-background-color)",
          },
        }}
      >
        <Loader secondary />
      </Dialog>
    </React.Fragment>
  );
};

export default BonusesListItem;

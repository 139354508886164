import React from "react";
import { Box } from "@mui/material";
import casino from "../../../assets/copyright/casino.svg";
import "./FooterPartners.css";
import { useTranslation } from "react-i18next";

export const FooterPartners = ({ slider }: { slider: boolean }) => {
  const { t, i18n } = useTranslation();

  return (
    <Box className="partners">
      {!slider && <h3 className="partners__heading">{t("footer.partners")}</h3>}

      <Box className="partners__image-container">
        <a href="https://casino.ru/casino-enomo/">
          <img className="partners__image" src={casino} alt="Casino.ru" />
        </a>
      </Box>
    </Box>
  );
};

import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

const analytics = AnalyticsBrowser.load(
  {
    cdnURL: "https://cdp-eu.customer.io",
    writeKey: process.env.REACT_APP_CUSTOMER_API_ID,
  },
  {
    integrations: {
      "Customer.io In-App Plugin": {
        siteId: process.env.REACT_APP_CUSTOMER_SITE_ID,
      },
    },
  }
);

export default analytics;

import { createSelector } from "reselect";
import { RootState } from "../../../../store/rootStore";

// Select the raw data from the Redux state
const selectRawData = (state: RootState) => state.bonuses;

// Create a memoized selector using reselect
export const inactiveBonusesListSelector = createSelector(
  selectRawData,
  (bonuses) => ({
    inactivatedBonuses: bonuses.activatedBonuses.filter((bonus) => {
      if (bonus.bonus_user_pers_type && bonus.bonus_user_fs_type) {
        return (
          (bonus.bonus_user_fs_type.status === "expired" ||
            bonus.bonus_user_fs_type.status === "fail" ||
            bonus.bonus_user_fs_type.status === "wagered" ||
            bonus.bonus_user_fs_type.status === "canceled") &&
          (bonus.bonus_user_pers_type.status === "expired" ||
            bonus.bonus_user_pers_type.status === "fail" ||
            bonus.bonus_user_pers_type.status === "wagered" ||
            bonus.bonus_user_pers_type.status === "canceled")
        );
      }
      if (bonus.bonus_user_fs_type && bonus.bonus_user_pers_type === null) {
        return (
          bonus.bonus_user_fs_type.status === "expired" ||
          bonus.bonus_user_fs_type.status === "fail" ||
          bonus.bonus_user_fs_type.status === "wagered" ||
          bonus.bonus_user_fs_type.status === "canceled"
        );
      }
      if (bonus.bonus_user_pers_type && bonus.bonus_user_fs_type === null) {
        return (
          bonus.bonus_user_pers_type.status === "expired" ||
          bonus.bonus_user_pers_type.status === "fail" ||
          bonus.bonus_user_pers_type.status === "wagered" ||
          bonus.bonus_user_pers_type.status === "canceled"
        );
      }
    }),
  })
);

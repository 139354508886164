import React from "react";
import { Container, Box } from "@mui/material";
import { SlotFilters } from "./SlotFilters/SlotFilters";
import { SlotCategories } from "./SlotCategories/SlotCategories";
import { Providers } from "./Providers/Providers";
import { SlotFiltersCategory } from "./SlotFiltersCategory/SlotFiltersCategory";
import "./Slots.css";
import { useAppSelector } from "../../store/hooks/store.hooks";

export const Slots = () => {
  const { isFiltered, searchGame } = useAppSelector((state) => {
    return state.slots;
  });
  console.log(searchGame);
  return (
    <Box className="slots">
      <Container className="container" maxWidth={false} disableGutters>
        <SlotFilters isMain={true} />
        {isFiltered ? (
          <>
            <SlotCategories
              isMain={true}
              heading="Все слоты"
              action="filterBySlots"
              searchGame={searchGame}
            />
            <SlotFiltersCategory isMain={true} />
          </>
        ) : (
          <>
            <SlotCategories
              isMain={true}
              heading="Популярные"
              action="filterByPopularity"
            />
            <SlotFiltersCategory isMain={true} />
            <SlotCategories
              isMain={true}
              heading="Новинки"
              action="filterByNew"
            />
            <SlotCategories
              isMain={true}
              heading="Все слоты"
              action="filterBySlots"
            />
          </>
        )}
      </Container>
      <Providers />
    </Box>
  );
};

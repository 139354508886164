import { Box, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import {
  cancelBonus,
  fetchMyActivatedBonuses,
} from "../../../../store/models/bonuses/bonuses.thunk";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import "./ProfileActiveBonuses.css";
import { activeBonusesListSelector } from "./ProfileActiveBonuses.selector";
import { ActiveBonusItem } from "../../../Bonuses/ActiveBonusItem/active.bonus.item";
import { Loader } from "../../../loader/loader";
import { ProfileNoActiveBonuses } from "./ProfileNoActiveBonuses";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { BonusDialog } from "../../../Bonuses/BonusesItem/BonusDialog/bonus.dialog";

const ProfileActiveBonuses = () => {
  const dispatch = useAppDispatch();
  const { activatedBonuses } = useAppSelector(activeBonusesListSelector);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchMyActivatedBonuses({}));
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { t } = useTranslation();
  const [infoMessage, setInfoMessage] = useState({ show: false, message: "" });
  const [infoMessageButton, setInfoMessageButton] = useState("");
  const [secondLoading, setSecondLoading] = useState(false);
  const navigate = useNavigate();
  const dialogHandler = () => {
    if (
      infoMessage.message === "Бонус был успешно отменён" ||
      infoMessage.message ===
        "Произошла ошибка - обратитесь в Службу Поддержки" ||
      infoMessage.message ===
        "К сожалению, бонус на этом этапе не может быть отменён"
    ) {
      closeDialog();
    }
    setInfoMessage({ show: false, message: "" });
  };
  const cancelBonusHandler = async (bonus_id: number) => {
    setSecondLoading(true);
    const response = await dispatch(cancelBonus({ bonus_id }));
    if (
      response.payload === "Бонус был успешно отменён" ||
      response.payload === "Произошла ошибка - обратитесь в Службу Поддержки" ||
      response.payload ===
        "К сожалению, бонус на этом этапе не может быть отменён"
    ) {
      setInfoMessageButton(t("buttons.close"));
    }
    setInfoMessage({ show: true, message: response.payload as string });
    setSecondLoading(false);
  };
  const closeDialog = () => setInfoMessage({ show: false, message: "" });

  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "", text: "АКТИВНЫЕ" },
        { link: "../bonuses/available", text: "ДОСТУПНЫЕ" },
        { link: "../bonuses/history", text: "ИСТОРИЯ" },
      ]}
    >
      <Box className="active-bonuses">
        <Box className="active-bonuses__container">
          {loading ? (
            <Loader />
          ) : activatedBonuses.length > 0 ? (
            <Box className="active-bonuses__bonuses-list">
              {activatedBonuses.map((bonus, index) => (
                <ActiveBonusItem
                  key={bonus.id}
                  {...bonus}
                  customClassName={""}
                  cancelBonus={cancelBonusHandler}
                />
              ))}
            </Box>
          ) : (
            <ProfileNoActiveBonuses />
          )}
        </Box>
      </Box>
      <BonusDialog
        infoMessage={infoMessage}
        loading={secondLoading}
        infoMessageButton={infoMessageButton}
        closeDialog={closeDialog}
        dialogHandler={dialogHandler}
      />
      <Dialog
        open={secondLoading}
        sx={{
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "transparent",
          ".MuiDialog-container": {
            alignItems: "center",
            backdropFilter: "blur(5px)",
            position: "relative",
          },
          ".MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          sx: {
            margin: isMobile ? "106px 14px 0px 14px" : "0px 14px 0px 14px",
            width: "100%",
            maxWidth: "400px",
            height: isMobile ? "200px" : "300px",
            maxHeight: "100%",
            borderRadius: "var(--primary-border-radius)",
            overflow: "hidden",
            backgroundColor: "var(--secondary-background-color)",
          },
        }}
      >
        <Loader secondary />
      </Dialog>
    </ProfileContentContainer>
  );
};

export default ProfileActiveBonuses;

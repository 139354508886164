import { configureStore } from "@reduxjs/toolkit";
import { IStore } from "./typing/interface";
import { rootReducer } from "./rootReducers";

const store = configureStore({
  reducer: rootReducer<IStore>(),
  // devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { useMemo } from "react";
import { createSelector } from "reselect";
import { RootState } from "../../../../store/rootStore";

// Select the raw data from the Redux state
const selectRawData = (state: RootState) => state.bonuses;

// Create a memoized selector using reselect
export const activeBonusesListSelector = createSelector(
  selectRawData,
  (bonuses) => ({
    activatedBonuses: bonuses.activatedBonuses.filter(
      (bonus) =>
        bonus.bonus_user_fs_type?.status === "active" ||
        bonus.bonus_user_fs_type?.status === "deposit" ||
        bonus.bonus_user_fs_type?.status === "wagering" ||
        bonus.bonus_user_fs_type?.status === "use" ||
        bonus.bonus_user_pers_type?.status === "active" ||
        bonus.bonus_user_pers_type?.status === "deposit" ||
        bonus.bonus_user_pers_type?.status === "wagering" ||
        bonus.bonus_user_pers_type?.status === "use"
    ),
  })
);

import React, { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks/store.hooks";
// import "./Payments.css";
import Main from "../Main/main";
import { setAuthorizationModal } from "../../store/models/auth/auth.slice";
import { Footer } from "../../components/Footer/Footer";

interface ILogin {}

const Login: React.FC<ILogin> = ({}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAuthorizationModal(true));
  }, [dispatch]);
  return (
    <React.Fragment>
      <Main />
      <Footer />
    </React.Fragment>
  );
};

export default Login;

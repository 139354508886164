import { BonusesAction } from "./typing/bonuses.enum";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IStore } from "../../typing/interface";
import { BonusesService } from "../../../services/bonuses/bonuses.service";
import {
  addMyActivatedBonuses,
  addAvailableBonuses,
  addBonuses,
} from "./bonuses.slice";

export const fetchBonuses = createAsyncThunk<null, {}, { state: IStore }>(
  BonusesAction.FETCH_BONUSES,
  async (_, { getState, dispatch }) => {
    const bonusesService = new BonusesService();

    const { result, error } = await bonusesService.fetchBonuses();

    if (error && error.response.status === 400) {
      // // console.log(result);
      // return error.response.data;
    }

    if (result) {
      dispatch(addBonuses(result));
      // await dispatch(fetchBonusesImages({ mobile }));
    }

    return null;
  }
);
export const fetchMyActivatedBonuses = createAsyncThunk<
  null,
  {},
  { state: IStore }
>(BonusesAction.FETCH_MY_ACTIVATED_BONUSES, async (_, { dispatch }) => {
  const bonusesService = new BonusesService();

  const { result, error } = await bonusesService.fetchActiveBonuses();

  if (error && error.response.status === 400) {
    // console.log(result);
    // return error.response.data;
  }
  if (result) {
    // console.log(result);
    dispatch(addMyActivatedBonuses(result));
  }

  return null;
});
export const fetchAvailableBonuses = createAsyncThunk<
  null,
  {},
  { state: IStore }
>(BonusesAction.FETCH_AVAILABLE_BONUSES, async (_, { dispatch }) => {
  const bonusesService = new BonusesService();

  const { result, error } = await bonusesService.fetchAvailableBonuses();

  if (error && error.response.status === 400) {
    // console.log(result);
    // return error.response.data;
  }

  if (result) {
    // console.log(result);
    dispatch(addAvailableBonuses(result));
  }

  return null;
});

export const activateGlobalBonus = createAsyncThunk<
  { message: string },
  { promocode: string },
  { state: IStore }
>(BonusesAction.ACTIVATE_GLOBAL_BONUS, async (_, { getState, dispatch }) => {
  const { promocode } = _;

  const bonusesService = new BonusesService();

  const { result, error } = await bonusesService.activateBonus(promocode);

  if (error && error.response.status === 400) {
    return error.response.data.error;
  }

  if (result) {
    // console.log(result.message);
  }

  return result.message;
});

export const fetchBonusById = createAsyncThunk<
  any,
  { bonusId: number },
  { state: IStore }
>(BonusesAction.FETCH_BONUS_BY_ID, async (_, { rejectWithValue }) => {
  const { bonusId } = _;
  const bonusesService = new BonusesService();
  const { result, error } = await bonusesService.fetchBonusByID(bonusId);
  if (error && error.response.status === 400) {
    return error.response.data;
  }

  if (error) {
    console.log(error);
    return rejectWithValue(error);
  }

  if (result) {
    return result.bonus;
  }
});

export const cancelBonus = createAsyncThunk<
  { message: string },
  { bonus_id: number },
  { state: IStore }
>(BonusesAction.CANCEL_BONUS, async (_, { dispatch, rejectWithValue }) => {
  const { bonus_id } = _;
  const bonusesService = new BonusesService();
  const { result, error } = await bonusesService.cancelBonus(bonus_id);
  if (error && error.response.status === 400) {
    return error.response.data;
  }
  dispatch(fetchMyActivatedBonuses({}));

  if (error) {
    console.log(error);
    return rejectWithValue(error);
  }

  if (result) {
    return result.message;
  }
});

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  InputAdornment,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import "./modal.css";
import { IDepositModalProps } from "../typing";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { ProfileMenuSelector } from "../../ProfileMenu/ProfileMenu.selector";
import { Loader } from "../../../loader/loader";
import { createDepositPayment } from "../../../../store/models/payment/payment.thunk";

export default function DepositModal({
  open,
  loading,
  method_id,
  image,
  min,
  max,
  paymentCurrency,
  closeHandler,
  depositHandler,
}: IDepositModalProps) {
  const { t, i18n } = useTranslation();
  const { currency } = useAppSelector(ProfileMenuSelector);
  const [openPromocode, setOpenPromocode] = useState(false);
  const [promocode, setPromocode] = useState("");
  const PaymentSchema = depositSchema(min, max, paymentCurrency, i18n);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const dispatch = useAppDispatch();

  const plusSums = [
    {
      currency: "RUB",
      amounts: [
        { text: "+500", value: 500 },
        { text: "+1000", value: 1000 },
        { text: "+2000", value: 2000 },
      ],
    },
    {
      currency: "EUR",
      amounts: [
        { text: "+20", value: 20 },
        { text: "+50", value: 50 },
        { text: "+100", value: 100 },
      ],
    },
    {
      currency: "USD",
      amounts: [
        { text: "+20", value: 20 },
        { text: "+50", value: 50 },
        { text: "+100", value: 100 },
      ],
    },
    {
      currency: "TRY",
      amounts: [
        { text: "+300", value: 300 },
        { text: "+500", value: 500 },
        { text: "+1000", value: 1000 },
      ],
    },
  ];

  useEffect(() => {
    const initAmount = Number(sessionStorage.getItem("enamsl"));
    const initPromocode = sessionStorage.getItem("enprsl");

    // debugger;
    if (initAmount) {
      if (+initAmount > min) {
        formik.setValues({ ...formik.values, amount: initAmount });
      } else {
        formik.setValues({ ...formik.values, amount: min });
      }
    }

    if (initPromocode) {
      setOpenPromocode(true);
      formik.setValues({ ...formik.values, promocode: initPromocode });
    }
  }, [open]);

  const handleModalClose = () => {
    setShowWarningModal(false);
  };

  const handleDeposit = () => {
    if (method_id && formik.values.amount !== null) {
      setShowWarningModal(false);
      depositHandler(
        Number(formik.values.amount),
        method_id,
        formik.values.promocode
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: Number(sessionStorage.getItem("enamsl")) || null,
      promocode: "",
    },
    validationSchema: PaymentSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (values.amount != null && currency == "RUB") {
        setShowWarningModal(true);
      }
    },
  });
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    const regExp = /^\d$/i;
    if (regExp.test(value[value.length - 1])) {
      formik.setValues({ ...formik.values, amount: Number(value) });
    }
    if (value === "") {
      formik.setValues({ ...formik.values, amount: null });
    }
  };
  return (
    <Dialog open={open} id="payment__modal" onClose={closeHandler}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <DialogTitle>{t("main.profile.payments.deposit.title")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t("main.profile.payments.deposit.method")}
          </Typography>
          <img src={image} alt="Payment method" />
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "var(--secondary-text-color)" }}
          >{`(${min} - ${max} ${paymentCurrency})`}</Typography>
        </DialogContent>
        <form onSubmit={formik.handleSubmit} className="payment-modal__form">
          <label htmlFor="amount">
            <Typography
              variant="body1"
              sx={{
                display: "block",
                color: "var(--primary-text-color)",
                fontSize: "14px",
                lineHeight: " 14px",
                marginBottom: "8.2px !important",
                fontWeight: "400 !important",
              }}
            >
              {t("main.profile.payments.deposit.amount")}
            </Typography>
          </label>
          <Box style={{ position: "relative" }}>
            <TextField
              error={Boolean(formik.errors.amount && formik.touched.amount)}
              size="small"
              margin="normal"
              autoComplete="off"
              className="payment-modal__amount"
              id="amount"
              type="text"
              fullWidth
              variant="standard"
              value={formik.values.amount ?? ""}
              onChange={onChangeHandler}
              InputProps={
                currency === "RUB"
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CurrencyRubleIcon
                            style={{
                              color: "rgba(255, 255, 255, 0.7)",
                              marginRight: "10px",
                              fontWeight: 700,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }
                  : currency === "EUR"
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <EuroIcon
                            style={{
                              color: "rgba(255, 255, 255, 0.7)",
                              marginRight: "10px",
                              fontWeight: 700,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <AttachMoneyIcon
                            style={{
                              color: "rgba(255, 255, 255, 0.7)",
                              marginRight: "10px",
                              fontWeight: 700,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }
              }
            />
            {formik.errors.amount && formik.touched.amount ? (
              <Typography
                className="Typography-error"
                component="p"
                variant="body2"
                sx={{
                  position: "absolute",
                  left: "10px",
                  bottom: "10px",
                }}
              >
                {formik.errors.amount}
              </Typography>
            ) : null}
          </Box>
          <Box className="payment-modal__button-container">
            {plusSums
              .filter((s) => s.currency === currency)[0]
              .amounts?.map((amount, i) => (
                <button
                  key={i}
                  type="button"
                  className="payment-modal__button"
                  onClick={() => {
                    formik.setValues({
                      ...formik.values,
                      amount: (formik.values.amount ?? 0) + amount.value,
                    });
                  }}
                >
                  {amount.text}
                </button>
              ))}
          </Box>
          {openPromocode ? (
            <>
              <label htmlFor="promocode">
                <Typography
                  variant="body1"
                  sx={{
                    display: "block",
                    color: "var(--primary-text-color)",
                    fontSize: "14px",
                    lineHeight: " 14px",
                    marginBottom: "8.2px !important",
                    fontWeight: "400 !important",
                  }}
                >
                  {t("main.profile.payments.deposit.promocode")}
                </Typography>
              </label>
              <Box style={{ position: "relative" }}>
                <TextField
                  error={Boolean(formik.errors.promocode)}
                  style={{ position: "relative" }}
                  size="small"
                  margin="normal"
                  fullWidth
                  id="promocode"
                  placeholder={t("registration.fields.promocode")}
                  name="promocode"
                  autoComplete="off"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setPromocode(e.currentTarget.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.promocode
                    .toUpperCase()
                    .replace(/\s/g, "")}
                  sx={{ margin: "0 0 24px 0" }}
                  InputProps={{
                    componentsProps: {
                      input: {
                        style: {
                          padding: "6px 10px",
                          height: "32px",
                          fontSize: "12px",
                          margin: "0px",
                        },
                      },
                    },
                  }}
                />
                {formik.errors.promocode && formik.touched.promocode ? (
                  <Typography
                    className="Typography-error"
                    component="p"
                    variant="body2"
                    sx={{
                      position: "absolute",
                      left: "10px",
                      bottom: "10px",
                    }}
                  >
                    {formik.errors.promocode}
                  </Typography>
                ) : null}
              </Box>
            </>
          ) : (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => setOpenPromocode(true)}
              sx={{
                fontWeight: "400 !important",
                color: "var(--primary-text-color)",
                fontSize: "12px",
                lineHeight: "18px",
                margin: "0 0 24px 5px",
                opacity: "0.75",
              }}
            >
              {t("registration.fields.confirmPromocode")}
            </Box>
          )}
          <DialogActions
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <button
              className="payment-modal__close"
              type="button"
              onClick={() => {
                formik.setValues({ ...formik.values, amount: 0 });
                closeHandler();
              }}
            >
              <CloseIcon
                style={{
                  color: "rgba(255,255,255,0.7)",
                }}
              />
            </button>
            <button className="payment-modal__submit" type="submit">
              {t("buttons.shorttopup")}
            </button>
          </DialogActions>
        </form>

        {/* <AmountSelectionModal
          open={showSuggesteModal}
          amounts={suggestedAmounts}
          onClose={handleModalClose}
          onSelectAmount={handleAmountSelect}
          currentAmount={Number(formik.values.amount)}
        /> */}
        <DepositWarningModal
          open={showWarningModal}
          onClose={handleModalClose}
          submit={handleDeposit}
        />
      </Box>
      {loading && <Loader />}
    </Dialog>
  );
}

const AmountSelectionModal: React.FC<{
  open: boolean;
  amounts: number[];
  currentAmount: number;
  onClose: () => void;
  onSelectAmount: (amount: number) => void;
}> = ({ currentAmount, open, amounts, onClose, onSelectAmount }) => {
  return (
    <Dialog
      sx={{
        maxWidth: "550px",
        margin: "0 auto",
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent
        sx={{
          backgroundColor: "var(--secondary-background-color)",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: "18px",
            fontWeight: "500 !important",
            color: "var(--primary-text-color)",
            marginBottom: "20px",
          }}
        >
          Сумма которую вы хотите внести, может быть не успешной
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: "16px",
            fontWeight: "400 !important",
            color: "var(--primary-text-color)",
            marginBottom: "20px",
          }}
        >
          Попробуйте одну из предложенных для успешного пополнения:
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            padding: "10px 0",
          }}
        >
          {amounts?.map((amount, index) => (
            <Button
              key={index}
              sx={{
                width: "30%",
                margin: "0.5rem",
                padding: "10px 15px",
                backgroundColor: "var(--primary-background-color)",
                border: "1px solid var(--primary-color) !important", // червона рамка
                // borderColor: "var(--primary-color)", // червоний текст
                // borderRadius: "8px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "var(--primary-color-hover)", // фон при наведенні
                },
              }}
              className={"Button  secondary-hover"}
              onClick={() => onSelectAmount(amount)}
            >
              {amount}
            </Button>
          ))}
        </Box>

        <Button
          sx={{
            backgroundColor: "var(--secondary-background-color)",
            marginTop: "20px",
            textDecoration: "underline",
            color: "var(--primary-color)", // червоний текст
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            "&:hover": {
              color: "var(--primary-color-hover)",
              backgroundColor: "var(--secondary-background-color)",
              textDecoration: "underline",
            },
          }}
          onClick={() => onSelectAmount(currentAmount)}
        >
          Продолжите с вашей выбранной суммой
        </Button>
      </DialogContent>
    </Dialog>
  );
};
type IDepositWarningModalProps = {
  open: boolean;
  onClose: () => void;
  submit: () => void;
};
const DepositWarningModal = ({
  open,
  onClose,
  submit,
}: IDepositWarningModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        maxWidth: "550px",
        margin: "0 auto",
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: "var(--secondary-background-color)",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            border: "2px solid var(--primary-color) !important",
            borderRadius: "var(--primary-border-radius)",
            padding: "2px 6px",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: "20px",
              fontWeight: "800 !important",
              color: "var(--primary-text-color)",
              marginBottom: "20px",
            }}
          >
            Внимание!
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: "16px",
              fontWeight: "400 !important",
              color: "var(--primary-text-color)",
              marginBottom: "20px",
            }}
          >
            Сумма платежа может изменится на странице платежной системы.
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: "16px",
              fontWeight: "800 !important",
              color: "var(--primary-text-color)",
              marginBottom: "20px",
            }}
          >
            Нужно перевести именно ту сумму, которая будет там указана, в ином
            случае, средства будут утеряны
          </Typography>
        </Box>
        <Button
          sx={{
            backgroundColor: "var(--secondary-background-color)",
            marginTop: "20px",
            textDecoration: "underline",
            color: "var(--primary-color)", // червоний текст
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            "&:hover": {
              color: "var(--primary-color-hover)",
              backgroundColor: "var(--secondary-background-color)",
              textDecoration: "underline",
            },
          }}
          onClick={submit}
        >
          Принять и продолжить
        </Button>
      </DialogContent>
    </Dialog>
  );
};

function depositSchema(min: number, max: number, currency: string, i18n: any) {
  return Yup.object().shape({
    amount: Yup.number()
      .required(i18n.t("main.profile.payments.deposit.validation.required"))
      .min(
        min,
        i18n.t("main.profile.payments.deposit.validation.min", {
          val: `${min} ${currency}`,
        })
      )
      .max(
        max,
        i18n.t("main.profile.payments.deposit.validation.max", {
          val: `${max} ${currency}`,
        })
      ),
    promocode: Yup.string().matches(
      /^[a-zA-Z0-9]*$/g,
      i18n.t("validationschema.promoCodeValid")
    ),
  });
}

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  InputAdornment,
  Box,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { MySelect } from "../../../select/select";
import { ProfileInput } from "../../../input/input";
import { ProfileMenuSelector } from "../../ProfileMenu/ProfileMenu.selector";
import { IGetBanksPayload, IWithdrawModalProps } from "../typing";
import * as Yup from "yup";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import "./modal.css";
import { Loader } from "../../../loader/loader";
import { repayBanks } from "../../../../constants/Constants";

export default function WithdrawalModal({
  open,
  method_type,
  method_id,
  image,
  min,
  max,
  paymentCurrency,
  closeHandler,
  withdrawalHandler,
}: // loading,
IWithdrawModalProps) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { currency, userBalance } = useAppSelector(ProfileMenuSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [banks, setBanks] = useState<any>([]);

  const PaymentSchema = Yup.object().shape({
    amount: Yup.number()
      .required(i18n.t("main.profile.payments.deposit.validation.required"))
      .min(
        min,
        i18n.t("main.profile.payments.deposit.validation.min", {
          val: `${min} ${currency}`,
        })
      )
      .max(
        max,
        i18n.t("main.profile.payments.deposit.validation.max", {
          val: `${max} ${currency}`,
        })
      ),
    credentials: Yup.string()
      .required(i18n.t("main.profile.payments.deposit.validation.requiredVal"))
      .when(method_type, (methodType: string | string[], schema) => {
        if (schema.deps[0] === "card") {
          return schema.matches(
            /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|(50|[56-69])[0-9]{10,17})|220[0-4][0-9]{12}|(3528|3529)[0-9]{11}|35[3-8][0-9]{12}|(1800|2131)[0-9]{11}|357266[0-9]{13}$/,
            i18n.t("main.profile.payments.deposit.validation.invalidCardNumber")
          );
        }
        if (schema.deps[0] === "crypto") {
          return schema.matches(
            /^[a-zA-Z0-9]{26,35}$/, // Проста перевірка для крипто-адреси
            i18n.t(
              "main.profile.payments.deposit.validation.invalidCryptoAddress"
            )
          );
        }
        if (schema.deps[0] === "mobile") {
          return schema.matches(
            /^\+?[1-9]\d{1,14}$/, // Міжнародний формат для номера телефону
            i18n.t(
              "main.profile.payments.deposit.validation.invalidMobileNumber"
            )
          );
        }
        return schema;
      }),
  });

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await fetch(
          "https://qr.nspk.ru/proxyapp/c2bmembers.json"
        );
        const data = await response.json();
        setBanks(data.dictionary);
      } catch (error) {
        console.error("Помилка завантаження списку банків:", error);
      }
    };

    if (method_id === "15" || method_id === "29") {
      fetchBanks();
    }

    if (method_id === "22" || method_id === "40") {
      setBanks(repayBanks);
    }
  }, [method_id]);

  const initialValues = {
    amount: null as number | null,
    credentials: "",
    bank: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PaymentSchema,
    onSubmit: async (values, { setErrors }) => {
      setLoading(true);
      if (values.amount) {
        const balance = parseFloat(userBalance.main_balance);
        const amount = values.amount;

        if (balance >= amount) {
          await withdrawalHandler(
            values.amount,
            values.credentials,
            method_id,
            values.bank
          );
        } else {
          setErrors({
            amount: t("main.profile.payments.deposit.validation.amount"),
          });
        }
      }
      setLoading(false);
    },
  });
  const allAvailable = () => {
    formik.setValues({
      ...formik.values,
      amount:
        +userBalance.main_balance > max ? +max : +userBalance.main_balance,
    });
  };
  const closeModal = () => {
    formik.setValues({ ...formik.values, amount: null });
    closeHandler();
  };
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    const regExp = /^\d$/i;
    if (
      regExp.test(value[value.length - 1]) ||
      value[value.length - 1] === "." ||
      value === ""
    ) {
      formik.setValues({ ...formik.values, amount: Number(value) });
    }
  };
  return (
    <>
      <Dialog open={open} id="payment__modal">
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <DialogTitle>Вывод средств</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Метод:</Typography>
            <img src={image} alt="Payment method" />
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: "var(--secondary-text-color)" }}
            >{`(${min} - ${max} ${paymentCurrency})`}</Typography>
          </DialogContent>
          <form onSubmit={formik.handleSubmit} className="payment-modal__form">
            <label htmlFor="amount">
              <Typography
                variant="body1"
                sx={{
                  display: "block",
                  color: "var(--primary-text-color)",
                  fontSize: "14px",
                  lineHeight: " 14px",
                  marginBottom: "8.2px !important",
                  fontWeight: "400 !important",
                }}
              >
                Укажите сумму
              </Typography>
            </label>
            <Box style={{ position: "relative" }}>
              <TextField
                error={Boolean(formik.errors.amount && formik.touched.amount)}
                autoFocus
                autoComplete="off"
                id="amount"
                className="payment-modal__amount"
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.amount}
                onChange={onChangeHandler}
                InputProps={
                  currency === "RUB"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CurrencyRubleIcon
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                marginRight: "10px",
                                fontWeight: 700,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : currency === "EUR"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <EuroIcon
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                marginRight: "10px",
                                fontWeight: 700,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <AttachMoneyIcon
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                marginRight: "10px",
                                fontWeight: 700,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                }
              />
              {formik.errors.amount && formik.touched.amount ? (
                <Typography
                  className="Typography-error"
                  component="p"
                  variant="body2"
                  sx={{
                    position: "absolute",
                    left: "10px",
                    bottom: "5px",
                  }}
                >
                  {formik.errors.amount}
                </Typography>
              ) : null}
            </Box>
            <Box className="payment-modal__button-container">
              <button
                type="button"
                className="payment-modal__button"
                onClick={allAvailable}
              >
                Все
              </button>
            </Box>

            {method_id === "15" ||
            method_id === "29" ||
            method_id === "22" ||
            method_id === "40" ? (
              <>
                <Box sx={{ marginBottom: "15px" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "block",
                      color: "var(--primary-text-color)",
                      fontSize: "14px",
                      lineHeight: " 14px",
                      marginBottom: "8.2px !important",
                      fontWeight: "400 !important",
                    }}
                  >
                    Выберите банк:
                  </Typography>
                  <Select
                    fullWidth
                    className="payment-modal__bank"
                    variant="outlined"
                    value={formik.values.bank}
                    onChange={(e) =>
                      formik.setFieldValue("bank", e.target.value)
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Выберите банк
                    </MenuItem>
                    {banks?.map(
                      (bank: {
                        bankName: string;
                        logoURL?: string;
                        schema: string;
                      }) => (
                        //@ts-ignore
                        <MenuItem key={bank.schema} value={bank.schema}>
                          {bank.bankName}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box>
              <ProfileInput
                error={formik.errors.credentials}
                touched={formik.touched.credentials}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values.credentials}
                name={"credentials"}
                label={
                  method_type === "mobile"
                    ? "Укажите номер телефона/счета"
                    : method_type === "card"
                    ? "Укажите номер карты"
                    : "Укажите адрес кошелька"
                }
                withoutPlaceholder={true}
              />
            </Box>

            <DialogActions sx={{ justifyContent: "center" }}>
              <button
                className="payment-modal__close"
                type="button"
                onClick={closeModal}
              >
                <CloseIcon
                  style={{
                    color: "rgba(255,255,255,0.7)",
                  }}
                />
              </button>
              <Button
                className="Button Button-secondary primary-hover payment-modal__submit"
                type="submit"
              >
                Вывести
              </Button>
            </DialogActions>
          </form>
        </Box>
        {loading && <Loader />}
      </Dialog>
    </>
  );
}

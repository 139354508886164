export enum SlotAction {
  SET_DEMO = "setDemo",
  SET_SLOTS = "setSlots",
  UPDATE_SLOTS = "updateSlots",
  CLEAR_SLOTS = "clearSlots",
  SET_PAGE = "setPage",
  SETT_SLOTS = "settSlots",
  SET_PROVIDER = "setProvider",
  SEARCH_PROVIDER = "searchProvider",
  SET_POPULAR_GAMES = "setPopularGames",
  SET_NEW_GAMES = "setNewGames",
  SET_FRUITS_GAMES = "setFruitsGames",
  SET_SEVENS_GAMES = "setSevensGames",
  SET_BOOKS_GAMES = "setBooksGames",
  SET_ANIMALS_GAMES = "setAnimalsGames",
  SET_BUYFS_GAMES = "setBuyFSGames",
  SET_RECOMMENDED_GAMES = "setRecommendedGames",
  SET_SLOT_GAMES = "setSlotGames",
  FIND_GAME = "findGame",
  CLEAR_FILTER = "clearFilter",
  SET_FILTER = "setFilter",
  GET_SLOTS = "getSlots",
  GETT_SLOTS = "gettSlots",
  VERIFY = "verify",
}

import React, { useEffect, useState } from "react";
import { Wallets } from "../../../wallets/Wallets";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import { Alert, Box, Collapse, Grid, Typography } from "@mui/material";
import { PaymentCard } from "../PaymentCard/PaymentCard";
import WithdrawalModal from "../PaymentModal/WithdrawalModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { IPaymentDialogState } from "../typing";
import {
  createWithdrawalPayment,
  getPaymentMethods,
  verifyWithrawals,
} from "../../../../store/models/payment/payment.thunk";
import { Trans, useTranslation } from "react-i18next";
import "./ProfileWithdrawal.css";
import { profileWithdrawalSelector } from "./ProfileWithdrawal.selector";
export const ProfileWithdrawal = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState<null | number>(null);
  const { paymentMethods, paymentLoading, info } = useAppSelector(
    profileWithdrawalSelector
  );
  const [dialog, setDialog] = useState<IPaymentDialogState>({
    modal: false,
    method_id: "0",
    method_type: "card",
    currency: "",
    image: "",
    min: 0,
    max: 0,
  });
  const dispatch = useAppDispatch();
  const withdrawalHandler = async (
    amount: number | null,
    credentials: string,
    method_id: string,
    bank_id: string | undefined
  ) => {
    if (amount) {
      await dispatch(
        createWithdrawalPayment({
          amount,
          credentials,
          method_id,
          bank_id,
        })
      );

      closeHandler();
    }
  };
  const handleOpen = (
    method_id: string,
    image: string,
    currency: string,
    min: number,
    max: number,
    method_type: IPaymentDialogState["method_type"]
  ) => {
    setDialog({
      modal: true,
      method_id,
      method_type,
      image,
      currency,
      min,
      max,
    });
  };
  const closeHandler = () => {
    setDialog({
      modal: false,
      method_type: "card",
      method_id: "0",
      image: "",
      currency: "",
      min: 0,
      max: 0,
    });
  };
  const [loading, setLoading] = useState<boolean>(true);
  const [blocked, setBlocked] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    const verify = async () => {
      const response = await dispatch(verifyWithrawals());
      if (response.payload?.error) {
        setLoading(false);
        return;
      }
      if (response.type === "verifyWithdrawals/fulfilled") {
        setLoading(false);
        setBlocked(response.payload);
      }
    };
    verify();
  }, []);
  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "../", text: t("main.profile.links.deposit") },
        { link: "", text: t("main.profile.links.withdrawal") },
        {
          link: "../payment-history",
          text: t("main.profile.links.history"),
        },
      ]}
    >
      <Box className="profile-withdrawal">
        {!paymentLoading && info?.message?.trim().length > 0 && (
          <Box sx={{ width: "100%" }}>
            <Collapse in={true}>
              <Alert
                style={{
                  alignItems: "center",
                  borderTopRightRadius: "14px",
                  borderTopLeftRadius: "14px",
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                severity={info.type}
              >
                {info.message}
              </Alert>
            </Collapse>
          </Box>
        )}
        {blocked && paymentMethods.length > 0 && (
          <Box sx={{ width: "100%" }}>
            <Collapse in={true}>
              <Alert
                style={{
                  alignItems: "center",
                  borderTopRightRadius: "14px",
                  borderTopLeftRadius: "14px",
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                severity="error"
              >
                Вывод с вашего игрового счета временно ограничен. Для
                возобновления возможности оформить заявку на выплату, обратитесь
                в Службу Поддержки
              </Alert>
            </Collapse>
          </Box>
        )}
        <Box className="profile-withdrawal__container">
          {blocked && <Box className="profile-withdrawal__restrictions" />}
          <Typography className="profile-withdrawal__title" component="h6">
            {t("main.profile.payments.withdrawaltitle")}
          </Typography>
          <Grid container className="profile-withdrawal__grid-container">
            {paymentMethods.map((pay) => {
              return (
                <Grid item key={pay.id}>
                  <PaymentCard
                    recommended={pay.recommended}
                    image={pay.image.src}
                    id={pay.id}
                    text={pay.name}
                    active={active}
                    buttonText={t("buttons.withdraw")}
                    onHover={() => {
                      setActive(pay.id);
                    }}
                    onLeave={() => setActive(null)}
                    onClick={() => {
                      handleOpen(
                        String(pay.id),
                        pay.image.src,
                        pay.currency,
                        pay.min,
                        pay.max,
                        pay.method_type
                      );
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Wallets />
      {dialog.modal && !blocked && (
        <WithdrawalModal
          method_type={dialog.method_type}
          open={dialog.modal}
          method_id={dialog.method_id}
          image={dialog.image}
          paymentCurrency={dialog.currency}
          min={dialog.min}
          max={dialog.max}
          closeHandler={closeHandler}
          withdrawalHandler={withdrawalHandler}
        />
      )}
    </ProfileContentContainer>
  );
};

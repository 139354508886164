import { createSlice } from "@reduxjs/toolkit";
import { ISliderSlide } from "./typing/slide.interface";
import reducers from "./slider.reducers";

const initialState: ISliderSlide[] = [];

export const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers,
});

export const { setSlides } = sliderSlice.actions;

export default sliderSlice.reducer;

import React from "react";
import { Box, Link, List, Typography } from "@mui/material";
import "./FooterNav.css";
import { useTranslation } from "react-i18next";
import { accordionData } from "../../../pages/Rules/Rules";
import { useNavigate } from "react-router-dom";

export const FooterNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLinkClick = (pathname: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/${pathname}`, { replace: true });
  };

  return (
    <Box className="footer-nav">
      <List className="footer-nav__list">
        {accordionData.slice(0, 3).map(({ pathname, labelKey }) => (
          <Typography key={pathname} className="footer-nav__item">
            <Link
              className="footer-nav__item-link"
              onClick={() => handleLinkClick(pathname)}
            >
              {t(labelKey)}
            </Link>
          </Typography>
        ))}
      </List>
      <List className="footer-nav__list">
        {accordionData.slice(3).map(({ pathname, labelKey }) => (
          <Typography key={pathname} className="footer-nav__item">
            <Link
              className="footer-nav__item-link"
              onClick={() => handleLinkClick(pathname)}
            >
              {t(labelKey)}
            </Link>
          </Typography>
        ))}
      </List>
    </Box>
  );
};

import axios from "axios";
import { useEffect } from "react";
import { Loader } from "../../../components/loader/loader";
import { useAppDispatch } from "../../../store/hooks/store.hooks";
import { verifyAuthUser } from "../../../store/models/auth/auth.thunk";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const YandexConfirmation = () => {
  useEffect(() => {
    const authenticateUser = async () => {
      const hash = window.location.hash;
      const tokenData = new URLSearchParams(hash.slice(1)); // Видаляємо символ #

      const accessToken = tokenData.get("access_token");
      const tokenType = tokenData.get("token_type");
      const expiresIn = tokenData.get("expires_in");
      const cid = tokenData.get("cid");

      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const visitorId = result.visitorId;

        // Відправляємо запит на сервер
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/auth/login/yandex/callback/success`,
          {
            accessToken,
            tokenType,
            expiresIn,
            cid,
            visitor: visitorId,
            promocod: localStorage.getItem("enprsl") || "", // Передаємо промокод, якщо є
            partner: localStorage.getItem("regkey") || "", // Передаємо partner, якщо є
          },
          { withCredentials: true } // Налаштування для підтримки cookie, якщо потрібно
        );

        console.log("Server response with Data:", response.data);
        localStorage.setItem("game-token", response.data.accessToken);
        localStorage.removeItem("enprsl");
        localStorage.removeItem("regkey");
        window.close();
      } catch (error) {
        console.error("Error sending token to server:", error);
      }
    };

    // Викликаємо асинхронну функцію
    authenticateUser();
  }, []);

  return <Loader></Loader>;
};

import React from "react";
import { Box, Container } from "@mui/material";
import "./coins.css";
import { PartnerBody } from "../../components/partner/partnerBody/partnerBody";
import { PartnerHeader } from "../../components/partner/partnerHeader/partnerHeader";
import { Wallets } from "../../components/wallets/Wallets";
import { useMediaQuery } from "react-responsive";
// import BonusesList from "../../components/Bonuses/BonusesList";

const Coins = ({}) => {
  const isBig = useMediaQuery({ minWidth: 1599 });
  return (
    <Box className="">
      <Container className="coins container" maxWidth={false} disableGutters>
        coins
        {/* <PartnerHeader />
        <PartnerBody /> */}
        {/* <Wallets /> */}
      </Container>
    </Box>
  );
};

export default Coins;

import { Box } from "@mui/system";
import React from "react";

type ICircleProgressProps = {
  percents: number;
};
export const CircleProgress = ({ percents }: ICircleProgressProps) => {
  return (
    <Box className="circle">
      <svg
        viewBox="-18.5 -18.5 185 185"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: "rotate(-90deg)",
        }}
      >
        <circle
          r="70"
          cx="70"
          cy="70"
          fill="transparent"
          stroke="#131a2f"
          strokeWidth="15"
          strokeDasharray="440px"
          strokeDashoffset="0"
        ></circle>
        <circle
          r="70"
          cx="70"
          cy="70"
          stroke="#df0043"
          strokeWidth="7"
          strokeLinecap="butt"
          strokeDashoffset={`calc(440px - (440px * ${percents})/100)`}
          fill="transparent"
          strokeDasharray="440px"
        ></circle>
        <text
          x="37px"
          y="87px"
          fill="#f8f8f8"
          fontSize="40px"
          fontWeight="bold"
          style={{
            transform: `rotate(90deg) translate(${
              percents > 9 ? "-10" : "10"
            }px, -144px)`,
          }}
        >
          {percents}%
        </text>
      </svg>
    </Box>
  );
};

import { PayloadAction } from "@reduxjs/toolkit";
import { SlotAction } from "./typing/slot.enum";
import { ISlots, IStoreProvider, IStoreSlot } from "./typing/slot.interface";

const reducers = {
  [SlotAction.SET_DEMO]: (
    state: ISlots,
    action: PayloadAction<boolean>
  ): void => {
    state.isDemo = action.payload;
  },
  [SlotAction.SET_SLOTS]: (
    state: ISlots,
    action: PayloadAction<{ games: IStoreSlot[]; count: number }>
  ): ISlots => {
    state.games = action.payload.games;
    state.gamesCount = action.payload.count;
    return state;
  },
  [SlotAction.UPDATE_SLOTS]: (
    state: ISlots,
    action: PayloadAction<{ games: IStoreSlot[]; count: number }>
  ): ISlots => {
    const copy = [
      ...JSON.parse(JSON.stringify(state.games)),
      ...action.payload.games,
    ];
    state.gamesCount = action.payload.count;
    state.games = copy;
    return state;
  },
  [SlotAction.CLEAR_SLOTS]: (state: ISlots): ISlots => {
    state.page = 1;
    state.gamesCount = 0;
    state.games = [];
    return state;
  },
  [SlotAction.SET_PAGE]: (state: ISlots): ISlots => {
    state.page = state.page + 1;
    return state;
  },
  [SlotAction.SET_PROVIDER]: (
    state: ISlots,
    action: PayloadAction<IStoreProvider[]>
  ): ISlots => {
    state.providers = action.payload;
    return state;
  },
  // [SlotAction.SEARCH_PROVIDER]: (
  //   state: ISlots,
  //   action: PayloadAction<string>
  // ): void => {
  //   const filteredArray = state.games.filter(
  //     (game) => game.provider.name === action.payload
  //   );
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_POPULAR_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.popular === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 1) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_NEW_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.new === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 3) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_ANIMALS_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.animals === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 4) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_BOOKS_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.books === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 5) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_BUYFS_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.buy_fs === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 6) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_SEVENS_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.seven === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 8) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_FRUITS_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter((game) => game.fruits === true);
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 7) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_RECOMMENDED_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games.filter(
  //     (game) => game.recommended === true
  //   );
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 9) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  // [SlotAction.SET_SLOT_GAMES]: (state: ISlots): void => {
  //   const filteredArray = state.games;
  //   state.copy = filteredArray;
  //   state.isFiltered = true;
  //   const newFilters = state.filters.map((filter) => {
  //     if (filter.id === 2) {
  //       return { ...filter, active: true };
  //     }
  //     return { ...filter, active: false };
  //   });
  //   state.filters = newFilters;
  // },
  [SlotAction.FIND_GAME]: (
    state: ISlots,
    action: PayloadAction<string>
  ): void => {
    const searchStr = action.payload.trim().toLocaleLowerCase();
    state.searchGame = searchStr;
    state.isFiltered = true;
    const newFilters = state.filters.map((filter) => {
      return { ...filter, active: false };
    });
    state.filters = newFilters;
  },
  [SlotAction.CLEAR_FILTER]: (state: ISlots): void => {
    state.isFiltered = false;
    state.searchGame = null;
    state.games = [];
    state.page = 1;
    state.gamesCount = 0;
    const newFilters = state.filters.map((filter) => {
      return { ...filter, active: false };
    });
    state.filters = newFilters;
  },
  [SlotAction.SET_FILTER]: (
    state: ISlots,
    action: PayloadAction<string>
  ): void => {
    state.isFiltered = true;
    state.games = [];
    state.gamesCount = 0;
    state.page = 1;
    const newFilters = state.filters.map((filter) => {
      if (filter.action === action.payload) return { ...filter, active: true };
      return { ...filter, active: false };
    });
    state.filters = newFilters;
  },
};

export default reducers;

import React, { useEffect } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import { ProfileSubmitButton } from "../../ProfileSubmitButton/ProfileSubmitButton";
import selfie from "../../../../assets/profile/selfie.svg";
import passport from "../../../../assets/profile/passport.svg";
import "./ProfileVerification.css";
import { Trans, useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { verificationUser } from "../../../../store/models/profile/profile.thunk";
import { PersonalInfoSelector } from "../personalInfo.selector";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const ProfileVerification = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { verification, verificationStage } =
    useAppSelector(PersonalInfoSelector);
  // console.log(verification, verificationStage);
  const [file, setFile] = React.useState<{
    passport: File | null;
    selfie: File | null;
    additional: File | null;
  }>({ passport: null, selfie: null, additional: null });
  const [alertOpen, setAlertOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<{
    severity: AlertColor;
    message: string;
  }>({
    severity: "success",
    message: "",
  });
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(verificationUser(file));
    setLoading(false);
  };
  useEffect(() => {
    // console.log("verification: ", verification);
    if (verification) {
      setAlert({
        severity: "success",
        message: "Ваша заявка успешно прошла верификацию",
      });
    } else {
      switch (verificationStage) {
        case "expectation":
          setAlert({
            severity: "warning",
            message: "Вам нужно подать заявку на верификацию",
          });
          break;
        case "pending":
          setAlert({
            severity: "info",
            message: "Ваша заявка ожидает верификацию",
          });
          break;
        case "rejected":
          setAlert({
            severity: "error",
            message: "Ваша заявка на верификацию отклонена",
          });
          break;

        default:
          break;
      }
    }
    // // console.log("verification: ", verification);
    // // console.log("verificationStage: ", verificationStage);
  }, [verificationStage, verification]);

  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "..", text: t("main.profile.links.personalinfo") },
        {
          link: "../contacts",
          text: t("main.profile.links.contacts"),
        },
        { link: "", text: t("main.profile.links.verification") },
      ]}
    >
      <Box className="profile-verification">
        {true && (
          <Box sx={{ width: "100%" }}>
            <Collapse in={alertOpen}>
              <Alert
                style={{
                  borderTopRightRadius: "14px",
                  borderTopLeftRadius: "14px",
                }}
                severity={alert.severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alert.message}
              </Alert>
            </Collapse>
          </Box>
        )}

        <Box className="profile-verification__container">
          <Typography variant="body1" className="profile-verification__label">
            {t("main.profile.verification.label")}
          </Typography>
          <form
            encType="multipart/form-data"
            className="profile-verification__form"
            onSubmit={submitHandler}
          >
            <Typography
              variant="body1"
              className="profile-verification__label label"
            >
              {t("main.profile.verification.fields.file_upload")}
            </Typography>
            <Box className="profile-verification__info-container">
              <UploadProvider
                id="passport"
                file={file.passport}
                uploadHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFile({
                    ...file,
                    passport: event.target.files ? event.target.files[0] : null,
                  });
                }}
              >
                <Card
                  className="profile-verification__info"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={passport}
                    alt="passport"
                    style={{ display: "block" }}
                  />
                  <CardContent>Фото паспорта</CardContent>
                </Card>
              </UploadProvider>
              <UploadProvider
                id="selfie"
                file={file.selfie}
                uploadHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFile({
                    ...file,
                    selfie: event.target.files ? event.target.files[0] : null,
                  });
                }}
              >
                <Card
                  className="profile-verification__info"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={selfie} alt="selfie" style={{ display: "block" }} />
                  <CardContent>
                    {t("main.profile.verification.validation.passportselfie")}
                  </CardContent>
                </Card>
              </UploadProvider>
              <UploadProvider
                id="additional"
                file={file.additional}
                uploadHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFile({
                    ...file,
                    additional: event.target.files
                      ? event.target.files[0]
                      : null,
                  });
                }}
              >
                <Card
                  className="profile-verification__info"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={passport}
                    alt="additional passport"
                    style={{ display: "block" }}
                  />
                  <CardContent>Дополнительная страница паспорта</CardContent>
                </Card>
              </UploadProvider>
            </Box>
            <ProfileSubmitButton
              text={t("buttons.download")}
              disabled={
                verificationStage === "pending" ||
                verificationStage === "success" ||
                verificationStage === "rejected" ||
                verification ||
                loading ||
                file.passport === null ||
                file.selfie === null ||
                file.additional === null
                  ? true
                  : false
              }
              loading={loading}
            />
          </form>
          <Box>
            <Typography
              variant="body1"
              className="profile-verification__label label"
            >
              {t("main.profile.verification.validation.requirements")}
            </Typography>
            <Typography
              variant="body1"
              className="profile-verification__label requirements"
            >
              {t("main.profile.verification.validation.imageformat")}
            </Typography>
            <Typography
              variant="body1"
              className="profile-verification__label requirements"
            >
              {t("main.profile.verification.validation.passportformat")}
            </Typography>
            <Typography
              variant="body1"
              className="profile-verification__label requirements"
            >
              {t("main.profile.verification.validation.action")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ProfileContentContainer>
  );
};

type IUploadProviderProps = {
  id: "passport" | "selfie" | "additional";
  file: File | null;
  children: React.ReactNode;
  uploadHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const UploadProvider = ({
  id,
  file,
  children,
  uploadHandler,
}: IUploadProviderProps) => {
  const filePicker = React.useRef<HTMLInputElement | null>(null);
  const handlePicker = () => {
    if (filePicker.current) {
      filePicker.current.click();
    }
  };
  return (
    <Box
      onClick={handlePicker}
      sx={{
        display: "flex",
        position: "relative",
        flex: "1 1 auto",
        width: "100%",
      }}
    >
      <input
        className="profile-verification__input"
        ref={filePicker}
        type="file"
        onChange={uploadHandler}
        id={id}
        name={id}
        accept="image/*, .png,.jpg.,.jpeg,.gif,.webp"
      />
      {file && (
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "var(--primary-color)",
            zIndex: 2,
          }}
        >
          <CheckCircleIcon />
        </Box>
      )}
      {children}
    </Box>
  );
};

import { API_URL } from "../..";
import { RequestService } from "../../store/models/request/request.service";
import { initialStateSlots } from "../../store/models/slots/slot.slice";
import {
  ISlots,
  IStoreProvider,
  IStoreSlot,
} from "../../store/models/slots/typing/slot.interface";
// import { API_URL } from "../fetch";
import { ISlotsService } from "./typing/slots.interface";
import {
  ISlotsVerify,
  TCategories,
  TGameWithCategories,
  TGetSlots,
} from "./typing/slots.type";

export class SlotsService implements ISlotsService {
  private slots: ISlots;

  constructor(slots: ISlots = initialStateSlots) {
    this.slots = slots;
  }

  // public getSlots: TGetSlots = () => {
  //   return this.slots.games;
  // };
  // public getPopSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.popular === true);
  // };
  // public getNewSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.new === true);
  // };
  // public getAnimalsSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.animals === true);
  // };
  // public getBooksSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.books === true);
  // };
  // public getBuyFSSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.buy_fs === true);
  // };
  // public getFruitsSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.fruits === true);
  // };
  // public getSevensSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.seven === true);
  // };
  // public getRecommendedSlots: TGetSlots = () => {
  //   return this.slots.games.filter((game) => game.recommended === true);
  // };
  // public getGamesWithCategories: TGameWithCategories = () => {
  //   const categories = this.slots.filters;

  //   const response: TCategories[] = [];

  //   categories
  //     .filter((el) => el.categorie === true)
  //     .map((el) => {
  //       let game: IStoreSlot[] = [];
  //       if (el.name === "Все слоты") {
  //         game = this.getSlots();
  //       }
  //       if (el.name === "Популярные") {
  //         game = this.getPopSlots();
  //       }
  //       if (el.name === "Новинки") {
  //         game = this.getNewSlots();
  //       }
  //       if (el.name === "Фрукты") {
  //         game = this.getFruitsSlots();
  //       }
  //       if (el.name === "777") {
  //         game = this.getSevensSlots();
  //       }
  //       if (el.name === "Книги") {
  //         game = this.getBooksSlots();
  //       }
  //       if (el.name === "Животные") {
  //         game = this.getAnimalsSlots();
  //       }
  //       if (el.name === "Купи спины") {
  //         game = this.getBuyFSSlots();
  //       }
  //       if (el.name === "Рекомендуемые") {
  //         game = this.getRecommendedSlots();
  //       }
  //       response.push({
  //         categorie: el.name,
  //         games: game,
  //       });
  //     });

  //   return response;
  // };

  async verifyRestrictions() {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/slots`);

    return await request.run<ISlotsVerify>(url, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  }

  async fetchSlots() {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/slots/slots`);

    return await request.run<IStoreSlot[]>(url, {
      method: "GET",
    });
  }
  async fetchhSlots({
    page,
    provider,
    name,
    ...filter
  }: {
    [key: string]: boolean | string | number | null | undefined;
    page: number;
    provider: number | undefined;
    name?: string | null | undefined;
  }) {
    const request = new RequestService();
    const url = request.prepareUrl(`${API_URL}/api/slots/slotts`, {
      additionalQuery: { ...filter, page, provider, name },
    });
    return await request.run<{ games: IStoreSlot[]; count: number }>(url, {
      method: "GET",
    });
  }

  async fetchProviders() {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/slots/providers`);

    return await request.run<IStoreProvider[]>(url, {
      method: "GET",
    });
  }
}

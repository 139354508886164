import { PayloadAction } from "@reduxjs/toolkit";
import { SlideAction } from "./typing/slide.enum";
import { ISliderSlide } from "./typing/slide.interface";

const reducers = {
  [SlideAction.SET_SLIDES]: (
    state: ISliderSlide[],
    action: PayloadAction<ISliderSlide[]>
  ): void => {
    let newState = JSON.parse(JSON.stringify(state));
    newState = action.payload;
    return newState;
  },
};

export default reducers;

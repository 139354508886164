import { createAsyncThunk } from "@reduxjs/toolkit";
import { SlideAction } from "./typing/slide.enum";
import { SliderService } from "../../../services/slider/slider.service";
import { setSlides } from "./slider.slice";

export const getSlides = createAsyncThunk(
  SlideAction.GET_SLIDES,
  async (_, { getState, dispatch }) => {
    try {
      const slideService = new SliderService();
      const { result, error } = await slideService.getSlides();
      if (error && error.response.status === 400) {
      }

      if (result) {
        dispatch(setSlides(result.slides));
      }
      return null;
    } catch (error) {}
  }
);
